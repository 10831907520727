import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../../_actions';
import { userServices } from '../../../_services';

import Alert from '../../../_components/structure/alert';
import TextField from '../../../_components/forms/textField';
import Select from '../../../_components/forms/select';
import Button from '../../../_components/forms/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faExclamationTriangle, faCheckCircle, faTimesCircle, faUser, faInfoCircle, faCheck} from '@fortawesome/free-solid-svg-icons';
import { warningsConstants, alertConstants } from '../../../_constants';

class User extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = { 
            user: {id:"", name: "", phone: "", cpf: "", rg: "", birthday:"", gender: 1},
            redirect: false
        };

        this.submitUser = this.submitUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.back = this.back.bind(this);

        const { dispatch } = props;
        dispatch(alertActions.clear());
    }

    submitUser(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { user } = this.state;
        dispatch(alertActions.clear());

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        userServices.insertBeneficiary(token, user).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Novo Beneficiário", iconTitle: faUser, icon:faCheckCircle, message: warningsConstants.SC_NEW_BENEFICIARY, eventOnClose: this.back }));
            } else {
                dispatch(alertActions.warning({iconTitle: faUser, icon:faExclamationTriangle, title:"Novo Beneficiário", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( (error) => {
            console.log(error);
            const { dispatch } = this.props;
            const { status, data } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            } 
            if (status === 400) {
                dispatch(alertActions.clear());
                dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        });
    }
    
    onChange = evt => {
        let user = this.state.user;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        user[evt.target.name] = evt.target.value;
        this.setState({user});
    }

    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    checkData = evt =>{
        evt.preventDefault();
        const { dispatch } = this.props;
        const { user } = this.state;
        const { name, cpf, phone, rg, birthday, gender } = user;
        const content = (
            <div className="p-2">
                <Alert icon={faInfoCircle} sizeIcon="2x" sizeText="sm" type={alertConstants.INFO} text="Verifque com atenção os dados do novo beneficiário."/>
                <div className="row mb-3">
                    <div className="col text-left">
                        <span className="font-weight-bold text-dark d-block">Nome</span>
                        <span className="text-muted">{name}</span>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-4 text-left">
                        <span className="font-weight-bold text-dark d-block">RG</span>
                        <span className="text-muted">{rg === "" ? "Não informado" : rg}</span>
                    </div>
                    <div className="col-4 text-left">
                        <span className="font-weight-bold text-dark d-block">CPF</span>
                        <span className="text-muted">{cpf}</span>
                    </div>
                    <div className="col-4">
                        <span className="font-weight-bold text-dark d-block">Telefone</span>
                        <span className="text-muted">{phone === "" ? "Não informado" : phone}</span>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-6 text-left">
                        <span className="font-weight-bold text-dark d-block">Data de Nascimento</span>
                        <span className="text-muted">{birthday}</span>
                    </div>
                    <div className="col-6 text-left">
                        <span className="font-weight-bold text-dark d-block">Sexo</span>
                        <span className="text-muted">{ gender === 1 ? "Homem" : "Mulher"}</span>
                    </div>
                </div>
            </div>
        );
        dispatch(alertActions.clear());
        dispatch(alertActions.primary({
            iconTitle: faCheck, 
            title:"Novo Beneficiário", 
            textBtnPrimary: "Confirmar Cadastro",
            colorBtnPrimary: "success",
            content,
            eventPrimary: this.submitUser
        }));
    }

    back(e) {
        this.setState({redirect: '/usuarios'});
    }

    render() {
        const { user, redirect } = this.state;
        if (redirect) {
            return <Redirect push to={redirect} />;
        }
        const { name, cpf, phone, rg, birthday, gender } = user;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <section className="col-sm-12 col-md-12 col-lg-9 col-xl-8">
                        <h2 className="title-page"><FontAwesomeIcon icon={faUser} /> Novo Beneficiário</h2>
                        <hr/>
                        <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                        <div className="card p-3">
                            <div className="card-body">
                                <form className="row" onSubmit={this.checkData}>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={name} required={true} name="name" onChange={e => this.onChange(e)} respInvalid="Informe o nome do usuário" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,60}$/} type="text" placeholder="Nome do usuário" label="Nome" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={cpf} mask="cpf" required={true} name="cpf" onChange={e => this.onChange(e)} type="text" placeholder="000.000.000-00" label="CPF:" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={rg} required={false} name="rg" onChange={e => this.onChange(e)} type="text" placeholder="Número do RG" label="RG:" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={phone} mask="phone" required={false} name="phone" onChange={e => this.onChange(e)} type="tel" placeholder="(DDD) 00000-0000" label="Telefone" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={birthday} mask="date"  required={true} name="birthday" onChange={e => this.onChange(e)} type="text" placeholder="DD/MM/AAAA" label="Data de Nascimento" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <Select value={gender} options={[{text:"Masculino", value:1},{text:"Feminino", value:2}]} required={true} name="gender" onChange={e => this.onChange(e)} type="text" placeholder="Sexo" label="Sexo" size="lg" />
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <Button type="submit" icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                                        <Button link="/beneficiarios" outline={true} icon={faTimes} text="Cancelar" color="primary" className="ml-2 " />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(User);