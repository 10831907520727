import React from 'react';
import { alertConstants } from '../../_constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle, faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';

export default class Alert extends React.Component {

    render() {
        let { type, sizeIcon, sizeText, icon, className, text, children } = this.props;
        let iconView, classType;
        if (!sizeIcon) {
            sizeIcon = "1x";
        }
        if (!sizeText) {
            sizeText = "lg";
        }
        switch(type){
            case alertConstants.SUCCESS:
                classType = "alert-success";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={faCheckCircle} />;
                break;
            case alertConstants.WARNING:
                classType = "alert-warning";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={faExclamationTriangle} />;
                break;
            case alertConstants.ERROR:
                classType = "alert-danger";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={faExclamationTriangle} />;
                break;
            case alertConstants.INFO:
                classType = "alert-info";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={faInfoCircle} />;
                break;
            case alertConstants.CONFIRM:
                classType = "";
                iconView = false;
                break;
            case alertConstants.LIGHT:
                classType = "alert-light";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={icon} />;
                break;
            case alertConstants.SECONDARY:
                classType = "alert-secondary";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={icon} />;
                break;
            case alertConstants.PRIMARY:
                classType = "alert-primary";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={icon} />;
                break;
            case alertConstants.LOADING:
                classType = "alert-light";
                iconView = <FontAwesomeIcon size={sizeIcon} icon={faSync} spin />;
                break;
            default:
                classType = "";
                iconView = false;
        }
        return (
        <div className={className+" text-center alert "+classType}>
                {iconView && iconView} 
                {text && (<p className={"m-0 text-"+sizeText}>{text}</p>)}
                {children && children}
          </div>
        );
    }
}
