import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class MenuSide extends React.Component {


    render() {
        
        return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3">
                {   
                    this.props.items.map( (it, i) => 
                        <li key={i} className={"breadcrumb-item " + (i !== (this.props.items.length - 1) && "active")}>
                            { it.link !== null ? 
                            <span><Link to={it.link}>{it.icon && <FontAwesomeIcon icon={it.icon} />} {it.text}</Link></span> : 
                            <span>{it.icon && <FontAwesomeIcon icon={it.icon} />} {it.text}</span> }
                        </li>
                    ) 
                }
            </ol>
          </nav>
        );
    }
}
