import React from 'react';

export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {checked: (props.checked ? props.checked : false)};
        this.onCheck = this.onCheck.bind(this);
        this.change = this.change.bind(this);
    }

    label() {
        if (this.props.label === "" || this.props.label === undefined) {
            return false;
        }
        return (<label className="font-weight-bold" htmlFor={this.props.id}>{this.props.label}</label>)
    }

    onCheck(e) {
        //this.inputElement.checked = !this.props.checked;
        if (this.props.onChange) {
            this.props.onChange(e);
        }

        this.setState({checked: e.target.checked});
    }

    change(checked){
        //this.inputElement.checked = !this.props.checked;
        this.setState({checked: checked});
    }

    render() {
        let label = this.label();
        const {className, value, id, name } = this.props;
        const { checked } = this.state;
        
        return (
            <div className={className && className}>
                {<input onChange={this.onCheck} type="checkbox" value={value} name={name} id={id} checked={checked} />} {label && label }
            </div>
        );
    }
}