import axios from 'axios/dist/axios'
//import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const utilServices = {
    stop,
    getCategories
}

const CancelToken = axios.CancelToken;
let cancel;

function getCategories() {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/categories.php`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}


function stop(message){
    if (cancel) {
        cancel(message);
    }
}
