import React from "react";
import { Router, Route, Switch } from 'react-router-dom';
import { history } from "./_store/history";
import { alertActions } from './_actions/alert.actions';
import { PrivateRoute } from './_routes/privateRoute';
import { connect } from "react-redux";


import homeAdmin  from "./_pages/admin/home";
import homeClient  from "./_pages/client/home";
import Login  from "./_pages/login";
import Logout  from "./_pages/logout";


class App extends React.Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        history.listen((location, action) => {
            if (Object.entries(this.props.alert).length > 0 && this.props.alert.constructor === Object) {
                dispatch(alertActions.clear());
            }
        });
    }

    render() {
        let pvRoute = <Route path="/" component={Login} />;
        if (this.props.authentication.loggedIn) {
            if (this.props.authentication.currentUser.type.code === 2) { //Se é um administrador
                pvRoute  = (<PrivateRoute path="/" component={homeAdmin} />)
            } else if (this.props.authentication.currentUser.type.code === 1) { //Se é um cliente
                pvRoute  = (<PrivateRoute path="/" component={homeClient} />)
            }
        }

        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        { pvRoute } 
                    </Switch>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};
  
export default connect(mapStateToProps)(App);