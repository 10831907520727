import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../../_actions';
import { locationServices, citiesServices, specialitiesServices, proceduresServices } from '../../../_services';

import TextField from '../../../_components/forms/textField';
import InputFile from '../../../_components/forms/inputFile';
import Button from '../../../_components/forms/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCog, faSave, faExclamationTriangle, faCheckCircle, faTimesCircle, faBuilding, faKey, faMapMarkedAlt, faStethoscope, faPencilAlt, faTrashAlt, faPlusCircle, faTrash, faInfoCircle, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { warningsConstants, alertConstants } from '../../../_constants';
import Select from '../../../_components/forms/select';
import Card from '../../../_components/structure/card';
import Alert from '../../../_components/structure/alert';
import Service from './service';

class Location extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        const { dispatch } = props;
        
        this.state = { 
            location: {
                image: null,
                cep: "",
                name:"",
                street: "",
                number: "",
                district: "",
                city: false,
                state: false,
                services: [],
                complement: "",
                phone1: "", phone2: "", phone3:""
            },
            listProcedures: [],
            listSpecialities: [],
            cepLoading: false,
            redirect: false,
            states: [],
            statesComplete: [],
            cities: []
        };
        
        this.submitLocation = this.submitLocation.bind(this);
        this.onChange = this.onChange.bind(this);
        this.back = this.back.bind(this);
        this.openNewService = this.openNewService.bind(this);
        this.openEditService = this.openEditService.bind(this);
        this.openDisabledService = this.openDisabledService.bind(this);

        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
    }

    submitLocation(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { location } = this.state;
        dispatch(alertActions.clear());

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        locationServices.insert(token, location).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Novo local de atendimento", iconTitle: faBuilding, icon:faCheckCircle, message: warningsConstants.SC_NEW_SERVICE_LOCATION, eventOnClose: this.back}));
            } else {
                dispatch(alertActions.warning({iconTitle: faBuilding, icon:faExclamationTriangle, title:"Novo local de atendimento", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( error =>{
            console.log(error.response);
            const { dispatch } = this.props;
            const { status, data } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Novo local de atendimento", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
        });
    }

    back(e) {
        this.setState({redirect: '/locais'});
    }
    
    onChange = evt => {
        let location = this.state.location;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }

        if (evt.target.name === "state") {
            location[evt.target.name] = {id:evt.target.value, name:evt.target.querySelector(`option[value="${evt.target.value}"]`).textContent};
            this.loadCities(evt.target.value);
        } else if (evt.target.name === "city"){
            location[evt.target.name] = {id:evt.target.value, name:evt.target.querySelector(`option[value="${evt.target.value}"]`).textContent};
        } else if (evt.target.name === "cep"){
            location[evt.target.name] = evt.target.value;
            if (evt.target.value.length === 9) {
                //this.setState({cepLoading: true});
                //this.loadStreet(evt.target.value);
                this.searchCEP();
            }
        } else {
            location[evt.target.name] = evt.target.value;
        }

        this.setState({location});
    }
   
    componentDidMount() {
        this._isMounted = true;
        this.loadSpecialities();
        this.loadStates();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    searchCEP = () => {
        let { statesComplete, location} = this.state;
        if (location.cep === "") {
            return;
        }

        this.setState({cepLoading: true});

        citiesServices.searchCEP(location.cep).then(res => {
            const { code, data } = res.data;
            if (code === "OK") {
                location.street = data.street;
                location.district = data.district;
                this.setState({location});
                
                for (var x = 0 ; x < statesComplete.length; x++) {
                    if (data.uf === statesComplete[x].acronym) {
                        location.state = {id:parseInt(statesComplete[x].id), name:statesComplete[x].name};
                        this.setState({location});
                        this.loadCities(parseInt(statesComplete[x].id), data.city);
                        break;
                    }
                }
            }
            this.setState({cepLoading: false});
        }).catch( error =>{
            console.log(error);
            this.setState({cepLoading: false});
        }); 
    }


    /******** SERVIÇOS ********/
    openNewService() {
        const { dispatch } = this.props;
        const { listSpecialities, listProcedures } = this.state;
        dispatch(alertActions.dialog({
            iconTitle: faStethoscope,
            title: "Novo serviço",
            content: (
                <Service 
                    onSave={this.onSaveNewService} 
                    onCancel={()=>{
                        const { dispatch } = this.props;
                        dispatch(alertActions.clear());
                    }} 
                    specialities={listSpecialities}
                    procedures={listProcedures}
                    />
                ),
        }));
    }
    onSaveNewService = (service) => {
        const { dispatch } = this.props;
        let location = this.state.location;

        for (var i = 0; i <location.services.length; i++) {
            if (parseInt(location.services[i].procedure) === parseInt(service.procedure) && parseInt(location.services[i].speciality) === parseInt(service.speciality)) {
                return warningsConstants.ER_SPECIALITY_ALREADY_SELECTED;
            }
        }

        location.services.push(service);
        this.setState({location});
        dispatch(alertActions.clear());
        return false;
    }
    getNameService = (procedure, speciality) => {
        const {listProcedures, listSpecialities} = this.state;  
        let text = '';
        for(var i = 0; i < listSpecialities.length; i++){
            if (parseInt(speciality) === parseInt(listSpecialities[i].value)) {
                text = listSpecialities[i].text;
                break;
            }
        }

        for(i = 0; i < listProcedures.length; i++){
            if (parseInt(procedure) === parseInt(listProcedures[i].value)) {
                return `${text} - ${listProcedures[i].text}`;
            }
        }
    }
    openDisabledService = (service) => {
        const { dispatch } = this.props;

        dispatch(alertActions.confirm({
            iconTitle: faTrash,
            title: "Excluir serviço",
            icon: faInfoCircle,
            message: (<div>{"Tem certeza que deseja excluir este serviço "}<b className="text-pink">({this.getNameService(service.procedure, service.speciality)})</b>{" ?"}</div>),
            eventPrimary: (e, close) => {
                let { location } = this.state;
                location.services.splice(service.idx, 1);
                this.setState({location});
                dispatch(alertActions.clear());
            }
        }));
    }
    openEditService(service) {
        const { dispatch } = this.props;
        const { listSpecialities, listProcedures } = this.state;
        dispatch(alertActions.dialog({
            iconTitle: faStethoscope,
            title: "Editar serviço",
            content: (
                <Service 
                    service={service} 
                    onSave={this.onSaveEditService} 
                    onCancel={()=>{
                        const { dispatch } = this.props;
                        dispatch(alertActions.clear());
                    }} 
                    specialities={listSpecialities}
                    procedures={listProcedures}
                    />
                ),
        }));
    }
    onSaveEditService = (service) => {
        const { dispatch } = this.props;
        let location = this.state.location;

        for (var i = 0; i < location.services.length; i++) {
            if (parseInt(location.services[i].procedure) === parseInt(service.procedure) && parseInt(location.services[i].speciality) === parseInt(service.speciality) && service.idx !== i) {
                return warningsConstants.ER_SPECIALITY_ALREADY_SELECTED;
            }
        }
        let servi = Object.assign({}, service);
        delete servi.idx;
        location.services[service.idx] = servi;
        this.setState({location});
        dispatch(alertActions.clear());
        return false;
    }
    /******** SERVIÇOS ********/



    loadSpecialities() {
        this.setState({ loading: true });
        const { dispatch } = this.props;
        
        specialitiesServices.getSpecialities().then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listSpecialities = [];
                
                for (var i = 0;i<data.length;i++) {
                    listSpecialities.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                
                if (data.length > 0) {
                    this.setState({ listSpecialities });
                } else {
                    this.setState({  error: { message: warningsConstants.WA_CATEGORY_NOT_FOUND }, loading: false});
                }
            } else {
                this.setState({ error: res.data, loading: false });
            }

            return proceduresServices.getProcedures();
        }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listProcedures = [];
                
                for (var i = 0;i<data.length;i++) {
                    listProcedures.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                
                if (data.length > 0) {
                    this.setState({ listProcedures, loading: false  });
                } else {
                    this.setState({  error: { message: warningsConstants.WA_CATEGORY_NOT_FOUND }, loading: false});
                }
            } else {
                this.setState({ error: res.data, loading: false });
            }

            dispatch(alertActions.clear());
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, loading: false });
            }
        }); 
    }

    loadStates() {
        this.setState({ loading: true });
        citiesServices.getStates().then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                if (data.length > 0) {
                    let states = [];
                    for(var i = 0;i<data.length;i++){
                        states.push({
                            text: data[i].name,
                            value: data[i].id
                        });
                    }
                    if (this._isMounted) {
                        this.setState({ statesComplete: data, states, loading: false  });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({  error: { message: warningsConstants.WA_STATES_NOT_FOUND }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, loading: false });
                }
            }
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, loading: false });
            }
        }); 
    }

    loadCities(uf, citySelected) {
        
        this.setState({ cities: [{text: "Carregando...", value: 0}] });
        let { location } = this.state;

        citiesServices.getCities(uf).then(res => {
            const {code, data} = res.data;
            if (code === "OK") {
                if (data.length > 0) {
                    let cities = [];
                    for(var i = 0;i<data.length;i++){
                        cities.push({
                            text: data[i].name,
                            value: data[i].id
                        });
                        
                        if (citySelected === data[i].name) {
                            location.city = {id: data[i].id, name:data[i].name};
                            this.setState({location});
                        }
                    }
                    if (this._isMounted) {
                        this.setState({ cities });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({  error: { message: warningsConstants.WA_CITIES_NOT_FOUND }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, loading: false });
                }
            }
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, loading: false });
            }
        }); 
    }

    loadStreet(cep) {
        const { token } = this.props.authentication;
        citiesServices.getStreet(token, cep).then(res => {
            const {code, street} = res.data;
            if (code === "OK" && street) {
                let {location} = this.state;
                location.street = street.name;
                this.setState({ location });
            }
            this.setState({cepLoading: false});
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status) {
                if (status === 403) {
                    dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                    return;
                }
            }
            this.setState({cepLoading: false});
        }); 
    }

    onCompleteUpload = (file, preview) => {
        let location = this.state.location;
        location.image = file;
        this.setState({location});
    }

    render() {
        const { location, redirect, states, cities, cepLoading } = this.state;
        if (redirect) {
            return <Redirect push to={redirect} />;
        }
        const { cep, name, street, number, district, complement, state, city, services, phone1, phone2, phone3, image } = location;
        return (
            <div className="main">
                <section className="col-12">
                    <form onSubmit={this.submitLocation}>
                        <h2 className="title-page"><FontAwesomeIcon icon={faBuilding} /> Novo local de atendimento</h2>
                        <hr/>
                        <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                                <Card className="p-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="font-weight-bold text-primary m-0">
                                            <FontAwesomeIcon icon={faStethoscope} /> Serviços
                                        </h4>
                                        <Button evtClick={this.openNewService} outline={true} size="sm" text="Novo" color="info" icon={faPlusCircle}/>
                                    </div>
                                    <div className="list-group mt-3">
                                    {
                                        services.length > 0 ?
                                        services.map( (servi, i) => (
                                            <div key={i} className="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                                                <div>
                                                    <b>{ this.getNameService(servi.procedure, servi.speciality) }</b>
                                                </div>
                                                <div className="">
                                                    <Button icon={faPencilAlt} evtClick={{fn:this.openEditService, params: {...servi, idx:i}}} outline={true} color="info"/>
                                                    <Button icon={faTrashAlt} evtClick={{fn:this.openDisabledService, params: {...servi, idx:i}}} outline={true} className="ml-2"/>
                                                </div>
                                            </div>
                                        )) :
                                        <Alert type={alertConstants.LIGHT} icon={faExclamationTriangle} text={warningsConstants.WA_SPECIALITY_NOT_FOUND}/>
                                    }
                                    </div>
                                </Card>
                            </div>
                        
                            <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7 mb-3">
                                <Card className="p-2" >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="font-weight-bold text-primary m-0">
                                            <FontAwesomeIcon icon={faMapMarkedAlt} /> Endereço
                                        </h4>
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex mb-4 justify-content-center">
                                                <InputFile rounded={true} photo={image} onComplete={file => this.onCompleteUpload(file)} name="image" typeFile="image" text="Enviar Logo" iconButton={faFileImage}/>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                <TextField required={true} value={phone1} mask="phone" name="phone1" onChange={e => this.onChange(e)} type="text" placeholder="(DDD) 0000-0000" label="Telefone 1" />
                                            </div>

                                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                <TextField required={false} value={phone2} mask="phone" name="phone2" onChange={e => this.onChange(e)} type="text" placeholder="(DDD) 0000-0000" label="Telefone 2" />
                                            </div>

                                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                <TextField required={false} value={phone3} mask="phone" name="phone3" onChange={e => this.onChange(e)} type="text" placeholder="(DDD) 0000-0000" label="Telefone 3" />
                                            </div>

                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                                                <TextField value={name} required name="name" onChange={e => this.onChange(e)} respInvalid="Informe o nome do local" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,200}$/} type="text" placeholder="Ex.: Clínica..." label="Nome do Local" />
                                            </div>
                        
                                            <div className="col-sm-3 col-md-3 col-lg-4 col-xl-4 mb-3">
                                                <TextField required={false} value={cep} mask="cep" name="cep" onChange={e => this.onChange(e)} type="text" placeholder="00000-000" label="CEP" />
                                            </div>
                                            <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2 align-items-end d-flex mb-3">
                                                <Button disabled={cepLoading} type="button" evtClick={this.searchCEP} text={ cepLoading ? <span><FontAwesomeIcon icon={faCog} spin /> Buscando...</span> : "Buscar pelo CEP"} className="w-100"/>
                                            </div>
                                        </div>
                                        {
                                            cepLoading ? 
                                            <Alert type={alertConstants.LOADING} sizeText="sm" text={warningsConstants.LD_SEARCHING_CEP}/>
                                            : (
                                            <div className="row">
                                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-3">
                                                    <TextField value={street} required={false} name="street" onChange={e => this.onChange(e)} respInvalid="Informe o nome do logradouro" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,200}$/} type="text" placeholder="Ex.: Rua, Avenida..." label="Logradouro" />
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                    <TextField required={false} value={number} name="number" onChange={e => this.onChange(e)}  respInvalid="Informe o número do endereço" type="text" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{1,200}$/} placeholder="000" label="Número" />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <TextField required={false} value={district} name="district" onChange={e => this.onChange(e)} respInvalid="Informe o bairro." type="text" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,200}$/} placeholder="Bairro" label="Bairro" />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <TextField required={false} value={complement} name="complement" onChange={e => this.onChange(e)}  type="text" placeholder="Complemento" label="Complemento" />
                                                </div>
                                                <div className="text-left col-sm-6 col-md-5 col-lg-4 col-xl-4 mb-3">
                                                    <Select options={states} value={state.id} name="state" onChange={e => this.onChange(e)} placeholder="Selecione o estado" label="Estado" />
                                                </div>
                                                <div className="text-left col-sm-6 col-md-7 col-lg-8 col-xl-8 mb-3">
                                                    <Select options={cities} value={city.id} name="city" onChange={e => this.onChange(e)} placeholder="Selecione a cidade" label="Cidade" />
                                                </div> 
                                            </div>
                                            )
                                        }
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <hr/>
                        <div className="row d-flex justify-content-center">
                            <Button type="submit" icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                            <Button link="/locais" outline={true} icon={faTimes} text="Cancelar" color="primary" className="ml-2 " />
                        </div>
                    </form>
                </section>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(Location);