import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import Alert from '../../_components/structure/alert';
import Card from '../../_components/structure/card';
import Button from '../../_components/forms/button';
import InputGroup from '../../_components/forms/inputGroup';
import ListItems from '../../_components/structure/listItems';
import Pagination from '../../_components/structure/pagination';

import { alertConstants, warningsConstants, servicesConstants } from '../../_constants';
import { alertActions } from '../../_actions';
import { userServices, attendanceServices } from '../../_services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlusCircle, faSearch, faUsers, faKey, faUserAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';


class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {users:[], totalBeneficiaries: 0, limitBeneficiaries: 0, currentPage: 1, lastPage: 1, search: '', error: false, loading: true, status: true, redirect: false};
        this.loadData();

        this.loadUsers = this.loadUsers.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
    }

    loadUsers(page) {
        const { token } = this.props.authentication;
        const { search, status, limitBeneficiaries } = this.state;
        const { dispatch } = this.props;

        this.setState({ loading: true });
        
        userServices.getBeneficiaries(token, {
            search,
            type: 1,
            page,
            status
        }).then(res => {
            console.log(res);
            if (res.data.code === "OK") {
                let users = [];
                res.data.data.forEach(user => {
                    users.push({
                        key: user.id,
                        info:[
                            { name:"Nome", value: user.name, col: 5},
                            { name:"Dt. Nascimento", value: user.birthday.split("-").reverse().join("/") },
                            { name:"CPF", value: user.cpf === "" ? "--" : user.cpf },
                            { name:"RG", value: user.rg === "" ? "--" : user.rg },
                            { name:"Telefone", value: user.phone === "" ? "--" : user.phone }
                        ]
                    });
                });
                let sizeUsers = users.length;
                if (sizeUsers < limitBeneficiaries) {
                    for (var i = 0; i < (limitBeneficiaries - sizeUsers); i++) {
                        users.push({
                            key: `empty-${i}`,
                            col:12,
                            info:[{ name:"",   value: <Alert className="w-100" type={alertConstants.SECONDARY} icon={faUserAlt} sizeText="sm" text="Aqui tem vaga para mais um beneficiário."/>, col: 12}],
                        });
                    }
                }

                if (sizeUsers > 0) {
                    this.setState({ users, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: false, loading: false});
                } else {
                    this.setState({ users, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: {
                        message: warningsConstants.WA_USERS_NOT_FOUND
                    }, loading: false});
                }
            } else {
                this.setState({ error: res.data, users: [], currentPage: 1, lastPage: 1, loading: false });
            }
            dispatch(alertActions.clear());
        }).catch( error => {
            console.log(error);
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, users: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    loadData() {
        const { token } = this.props.authentication;
        console.log(token);
        const { dispatch } = this.props;
        attendanceServices.getDashboard(token).then(resp => {
            const {code, data} = resp.data;
            if (code === "OK") {
                this.setState({
                    totalBeneficiaries: parseInt(data.totalBeneficiaries),
                    limitBeneficiaries: parseInt(data.limitBeneficiaries)
                });
                dispatch(alertActions.clear());
            } else {
                dispatch(alertActions.warning({iconTitle: faExclamationTriangle, icon:faExclamationTriangle, title:"Carregando Dados", message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
            }
            this.loadUsers(1); 
        }).catch( (error) =>{
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faExclamationTriangle, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    componentWillUnmount() {
        userServices.stop(servicesConstants.CANCEL_REQUEST);
    }


    onChangeSearch(evt) {
        this.setState({ search: evt.target.value });
    }

    submitSearch(e) {
        e.preventDefault();
        this.loadUsers(1);
    }

    userIsActive = () => {
        const { currentUser } = this.props.authentication;
        if (currentUser.status === "4" || currentUser.status === "0") {
            return false;
        }
        return true;
    }

    render() {
        const { loading, error, users, lastPage, currentPage, redirect, totalBeneficiaries, limitBeneficiaries} = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        return (
            <div>
                <section className="m-3">

                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className="m-0 title-page"><FontAwesomeIcon icon={faUsers} /> Beneficiários</h2>
                        <Card className="">
                            <h5 className="text-dark m-0 font-weight-bold">
                            <FontAwesomeIcon icon={faUsers} /> Limite de Beneficiários {`${totalBeneficiaries}/${limitBeneficiaries}`}
                            </h5>
                        </Card>
                    </div>
                    <hr/>
                    {
                        this.userIsActive() &&
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            { totalBeneficiaries < limitBeneficiaries && 
                            <Button link="/beneficiarios/novo" text={"Novo beneficiario"} className="btn-fiell" icon={faPlusCircle} color={"success"}/>}
                            <form onSubmit={ this.submitSearch } className="d-flex w-50">
                                <InputGroup alue={this.state.search} name="search" onChange={e => this.onChangeSearch(e)} type="text" placeholder="Pesquise por beneficiarios" label={false} append={<Button type="submit" className="btn-fiell" text={"Pesquisar"} icon={faSearch} color={"primary"}/>}/>
                            </form>
                        </div>
                    }
                    <Card className="mb-2">
                        <Alert className="m-0" type={alertConstants.SECONDARY} icon={faInfoCircle} sizeText="sm" text="Caso seja necessário a alteração dos dados, entre em contato conosco."/>
                    </Card>
                    <Card>
                        { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                        { (!loading && error) && <Alert text={error.message} type={alertConstants.WARNING}></Alert> }
                        { (!loading && !error) &&  <ListItems items={users}/> }
                    </Card>
                </section>
                <footer>
                    { (!loading && !error && lastPage > 1) && 
                        <Pagination fnChangePage={this.loadUsers} currentPage={currentPage} lastPage={lastPage}/>
                    }   
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Users);