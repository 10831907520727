import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const authServices = {
    authenticateAgreed,
    authenticateClient,
    updateTokenPush,
    getDataUser,
    logout
}


function authenticateClient(cpf, birthday) {
    delete axios.defaults.headers.common['Authorization'];
    const options = {
        method: 'POST',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/login.php`,
        responseType: 'json',
        data: qs.stringify({cpf, birthday}),
        headers: { 
            'content-type': 'application/x-www-form-urlencoded'
        }
    };
    return axios(options).then(resp => {
        console.log(resp.data);
        if (resp.status !== 200) {
            throw resp.data;
        }
        
        return authServices.getDataUser(resp.data.token).then((res) => {
            return {token: resp.data.token, user: res.data.user};
        });
    });
}

function authenticateAgreed(login, password) {
    delete axios.defaults.headers.common['Authorization'];
    const options = {
        method: 'POST',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/login.php`,
        responseType: 'json',
        data: qs.stringify({login, password}),
        headers: { 
            'content-type': 'application/x-www-form-urlencoded'
        }
    };
    return axios(options).then(resp => {
        console.log(resp.data);
        if (resp.status !== 200) {
            throw resp.data;
        }
        
        return authServices.getDataUser(resp.data.token).then((res) => {
            return {token: resp.data.token, user: res.data.user};
        });
    });
}

function updateTokenPush(token, tokenPush){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}updateTokenPush`,{token: tokenPush});
}

function getDataUser(token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}services/read/userToken.php`);
}

function logout() {
    localStorage.removeItem('tokenSimplevida');
    localStorage.removeItem('userSimplevida');
}