import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../_actions';
import { userServices, citiesServices } from '../../_services';

import TextField from '../../_components/forms/textField';
import Button from '../../_components/forms/button';
import Alert from '../../_components/structure/alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faExclamationTriangle, faCheckCircle, faTimesCircle, faWrench, faExclamationCircle, faKey, faLock, faLockOpen, faIdCard, faMapMarkedAlt, faPlusCircle, faTrashAlt, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { warningsConstants, alertConstants } from '../../_constants';
import Select from '../../_components/forms/select';

class Settings extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = { 
            user: {name: "", phone: "", cpf: "", email: "", password: "", rePassword: ""},
            addresses: [],
            addrEdit: false,
            redirect: false,
            states: [],
            cities: []
        };

        this.loadData();

        this.submitUser = this.submitUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.openDisabledAddress = this.openDisabledAddress.bind(this);
        this.openEditAddress = this.openEditAddress.bind(this);
        this.saveEditAddress = this.saveEditAddress.bind(this);
        this.cancelEditAddress = this.cancelEditAddress.bind(this);
        this.openNewAddress = this.openNewAddress.bind(this);

        const { dispatch } = props;
        dispatch(alertActions.clear());
    }

    loadData() {
        const { token } = this.props.authentication;
        const { dispatch } = this.props;
        if (this.props.match.params.uid === "") {
            dispatch(alertActions.error({title:"Editando configurações", iconTitle: faWrench, icon:faExclamationCircle, message: warningsConstants.ER_PARAMS_URL}));
        } else {
            userServices.get(token, this.props.match.params.uid).then(resp => {
                const {code} = resp.data;
                if (code === "OK") {
                    let addresses = [];
                    let user = Object.assign(this.state.user, {   
                            id:resp.data.user.id, 
                            name:resp.data.user.name, 
                            cpf:resp.data.user.cpf, 
                            phone: resp.data.user.phone ? resp.data.user.phone.replace(/[^\d]+/g, '') : "", 
                            email: resp.data.user.email
                        });
                    addresses = resp.data.user.addresses;
                    this.setState({user, addresses});

                    dispatch(alertActions.clear());
                } else {
                    dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Editando configurações", message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
                }
            }).catch( (error) =>{
                const { status } = error.response;
                if (status === 403) {
                    dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                    return;
                }
                dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
            }); 
        }
    }

    submitUser(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { user, changePassword, addresses } = this.state;
        dispatch(alertActions.clear());

        //Senha não repetida
        if (changePassword) {
            if (user.password !== user.rePassword) {
                dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Ops...", message: warningsConstants.ER_PASSWORD_REPEAT}));
                return;
            }
        }

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        userServices.updateSettings(token, {...user, addresses}).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Editando configurações", iconTitle: faWrench, icon:faCheckCircle, message: warningsConstants.SC_UPDATE_USER}));
            } else {
                dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Editando configurações", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( error =>{
            console.log(error);
            const { dispatch } = this.props;
            const { status } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        });
    }
    
    onChange = evt => {
        let user = this.state.user;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        user[evt.target.name] = evt.target.value;
        this.setState({user});
    }

    onChangeAddress = evt => {
        let addr = this.state.addrEdit;

        if (evt.target.name === "uf") {
            addr[evt.target.name] = {id:evt.target.value, name:evt.target.querySelector(`option[value="${evt.target.value}"]`).textContent};
            this.loadCities(evt.target.value);
        } else if (evt.target.name === "city"){
            addr[evt.target.name] = {id:evt.target.value, name:evt.target.querySelector(`option[value="${evt.target.value}"]`).textContent};
        } else {
            addr[evt.target.name] = evt.target.value;
        }
        this.setState({addrEdit: addr});
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadStates();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadStates() {
        this.setState({ loading: true });
        citiesServices.getStates().then(res => {
            const {code } = res.data;
            if (code === "OK") {
                if (res.data.states.length > 0) {
                    let states = [];
                    for(var i = 0;i<res.data.states.length;i++){
                        states.push({
                            text: res.data.states[i].name,
                            value: res.data.states[i].id
                        });
                    }
                    if (this._isMounted) {
                        this.setState({ states });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({ error: {
                            message: warningsConstants.WA_STATES_NOT_FOUND
                        }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, currentPage: 1, lastPage: 1, loading: false });
                }
            }
        }).catch( error => {
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, currentPage: 1, lastPage: 1, loading: false });
            }
        }); 
    }

    loadCities(uf) {
        citiesServices.getCities(uf).then(res => {
            const {code} = res.data;
            if (code === "OK") {
                if (res.data.cities.length > 0) {
                    let cities = [];
                    for(var i = 0;i<res.data.cities.length;i++){
                        cities.push({
                            text: res.data.cities[i].name,
                            value: res.data.cities[i].id
                        });
                    }
                    if (this._isMounted) {
                        this.setState({ cities });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({ notifications: [], error: {
                            message: warningsConstants.WA_CITIES_NOT_FOUND
                        }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, notifications: [], currentPage: 1, lastPage: 1, loading: false });
                }
            }
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, notifications: [], currentPage: 1, lastPage: 1, loading: false });
            }
        }); 
    }

    openDisabledAddress(addr){
        const { dispatch } = this.props;
        let { addresses } = this.state;

        dispatch(alertActions.confirm({
            iconTitle: faTrash,
            title: "Excluir local de atendimento",
            icon: faInfoCircle,
            message: (<div>{"Tem certeza que deseja excluir este local "}<b className="text-pink">({ addr.city.name } - { addr.uf.name })</b>{" ?"}</div>),
            eventPrimary: (e, close) => {
                addresses.splice(addr.idx, 1);
                this.setState({addresses});
                dispatch(alertActions.clear());
            }
        }));
    }

    async openEditAddress(idx){
        const { addresses } = this.state;
        let addr = {idx, ...addresses[idx]};
        this.setState({addrEdit: addr});
        await this.loadCities(addr.uf.id);
    }

    openNewAddress(){
        let addr = {
            idx: null, id: null,
            street: "", number: "", district: "", 
            city: {id: 0, name:""}, 
            uf: {id: 0, name:""},
            complement: ""
        };
        this.setState({addrEdit: addr});
    }

    saveEditAddress () {
        let { addresses, addrEdit} = this.state;
        console.log(addrEdit);
        if (addrEdit.idx === null) {
            addresses.push({idx:addresses.length ,...addrEdit});
        } else {
            addresses[addrEdit.idx] = {...addrEdit};
        }
        this.setState({addresses, addrEdit: false});
    }

    cancelEditAddress () {
        this.setState({addrEdit: false});
    }

    changePassword(e){
        this.setState({changePassword: !this.state.changePassword});
    }

    render() {
        const { user, redirect, changePassword, addresses, addrEdit, states, cities } = this.state;
        if (redirect) {
            return <Redirect push to={redirect} />;
        }
        const { name, email, cpf, phone, password, rePassword } = user;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <section className="col-sm-12 col-md-12 col-lg-9 col-xl-8">
                        <h2 className="title-page"><FontAwesomeIcon icon={faWrench} /> Configurações</h2>
                        <hr/>
                        <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                        <div className="card p-3">
                            <div className="card-body">
                                <h4 className="font-weight-bold text-primary"><FontAwesomeIcon icon={faIdCard} /> Dados Pessoais</h4>
                                <form className="row" onSubmit={this.submitUser}>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={name} required={true} name="name" onChange={e => this.onChange(e)} respInvalid="Informe o nome do usuário" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,60}$/} type="text" placeholder="Nome do usuário" label="Nome" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={email} required={true} name="email" onChange={e => this.onChange(e)} respInvalid="E-mail inválido." pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/} type="email" placeholder="email@exemplo.com" label="E-mail" size="lg" />
                                    </div>
                                    
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={cpf} mask="cpf" required={false} name="cpf" onChange={e => this.onChange(e)} type="text" placeholder="000.000.000-00" label="CPF:" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={phone} mask="phone" required={false} name="phone" onChange={e => this.onChange(e)} type="tel" placeholder="(DDD) 00000-0000" label="Telefone" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-4  col-xl-4 mb-4">
                                        <TextField value={password} required={true} disabled={!changePassword} name="password" respInvalid="A senha deve conter no mínimo 4 digitos." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="Senha" size="lg" label="Senha" />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-4  col-xl-4 mb-4">
                                        <TextField value={rePassword} required={true} disabled={!changePassword} name="rePassword" respInvalid="Repita a senha." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="Repita a senha" size="lg" label="Repita a senha" />
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-4 d-flex align-items-end">
                                        {changePassword ? 
                                            <Button type="button" evtClick={this.changePassword} text="Não alterar senha" size="lg" className="btn-fiell w-100" color="primary" icon={faLockOpen}/> : 
                                            <Button type="button" evtClick={this.changePassword} text="Alterar senha" size="lg" className="btn-fiell w-100" color="info" icon={faLock}/>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="font-weight-bold text-primary m-0">
                                                <FontAwesomeIcon icon={faMapMarkedAlt} /> Locais de atendimento
                                            </h4>
                                            <Button evtClick={this.openNewAddress} outline={true} size="sm" text="Novo Local" color="info" icon={faPlusCircle}/>
                                        </div>
                                        <div className="list-group mt-3">
                                        {
                                            addresses.length > 0 ?
                                            addresses.map( (addr, i) => (
                                                <div key={i} className="list-group-item list-group-item-action d-flex justify-content-between">
                                                    <div>
                                                        <b>{ addr.city.name } - { addr.uf.name }</b><br/>
                                                        <small>{ addr.street }, { addr.number } - { addr.district }</small>
                                                        <small>{ addr.complement }</small>
                                                    </div>

                                                    <div className="">
                                                        <Button icon={faPencilAlt} evtClick={{fn:this.openEditAddress, params: i}} outline={true} color="info"/>
                                                        <Button icon={faTrashAlt} evtClick={{fn:this.openDisabledAddress, params: {...addr, idx:i}}} outline={true} className="ml-2"/>
                                                    </div>
                                                </div>
                                            )) :
                                            <Alert type={alertConstants.LIGHT} icon={faExclamationTriangle} text={warningsConstants.WA_ADDRESS_NOT_FOUND}/>
                                        }
                                        </div>
                                        { (addrEdit !== false)  && 
                                            (<div className="mt-2 card border-primary">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-3">
                                                            <TextField value={addrEdit.street} required={false} name="street" onChange={e => this.onChangeAddress(e)} respInvalid="Informe o nome do logradouro" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,200}$/} type="text" placeholder="Rua, Avenida..." label="Logradouro" />
                                                        </div>
                                                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                            <TextField required={false} value={addrEdit.number} name="number" onChange={e => this.onChangeAddress(e)}  respInvalid="Informe o número do endereço" type="text" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{1,200}$/} placeholder="000" label="Número" />
                                                        </div>
                                                        <div className="col-12 mb-3">
                                                            <TextField required={false} value={addrEdit.district} name="district" onChange={e => this.onChangeAddress(e)} respInvalid="Informe o bairro." type="text" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,200}$/} placeholder="Bairro" label="Bairro" />
                                                        </div>
                                                        <div className="text-left col-sm-6 col-md-5 col-lg-4 col-xl-4 mb-3">
                                                            <Select options={states} value={addrEdit.uf.id} name="uf" onChange={e => this.onChangeAddress(e)} placeholder="Selecione o estado" label="Estado" />
                                                        </div>
                                                        <div className="text-left col-sm-6 col-md-7 col-lg-8 col-xl-8 mb-3">
                                                            <Select options={cities} value={addrEdit.city.id} name="city"  onChange={e => this.onChangeAddress(e)} placeholder="Selecione a cidade" label="Cidade" />
                                                        </div>
                                                    </div>

                                                    <Button evtClick={this.saveEditAddress} icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                                                    <Button evtClick={this.cancelEditAddress} outline={true} icon={faTimes} text="Cancelar" color="primary" className="ml-2 " />
                                                </div>
                                            </div>) 
                                        }
                                        <hr/>
                                    </div>
                                    {!addrEdit && <div className="col-12 d-flex justify-content-center">
                                        <Button type="submit" icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                                        <Button link="/usuarios" outline={true} icon={faTimes} text="Cancelar" color="primary" className="ml-2 " />
                                    </div>}
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(Settings);