import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../../_actions';
import { userServices } from '../../../_services';

import TextField from '../../../_components/forms/textField';
import Button from '../../../_components/forms/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faExclamationTriangle, faCheckCircle, faTimesCircle, faUserMd, faExclamationCircle, faKey, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { warningsConstants } from '../../../_constants';

class User extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = { 
            user: {login: "", type: 1, password: "", rePassword: "", changePassword: false},
            redirect: false,
            states: [],
            cities: []
        };

        this.loadUser();
        this.submitUser = this.submitUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.back = this.back.bind(this);
        this.changePassword = this.changePassword.bind(this);

        const { dispatch } = props;
        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
    }

    loadUser() {
        const { token } = this.props.authentication;
        const { dispatch } = this.props;
        if (this.props.match.params.uid === "") {
            dispatch(alertActions.error({title:"Editando administrador", iconTitle: faUserMd, icon:faExclamationCircle, message: warningsConstants.ER_PARAMS_URL, eventOnClose: this.back}));
        } else {
            userServices.get(token, this.props.match.params.uid).then(resp => {
                const {code} = resp.data;
                if (code === "OK") {
                    let user = Object.assign(this.state.user, {   
                            id:resp.data.user.id, 
                            login: resp.data.user.login
                        });
                    this.setState({user});

                    dispatch(alertActions.clear());
                } else {
                    dispatch(alertActions.warning({iconTitle: faUserMd, icon:faExclamationTriangle, title:"Editando administrador", message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
                }
            }).catch( (error) =>{
                const { status, data } = error.response;
                if (status === 403) {
                    dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                    return;
                }
                dispatch(alertActions.warning({iconTitle: faUserMd, icon:faExclamationTriangle, title:"Carregando Dados", message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
            }); 
        }
    }

    submitUser(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        let { user } = this.state;
        dispatch(alertActions.clear());

        //Senha não repetida
        if (user.changePassword) {
            if (user.password !== user.rePassword) {
                dispatch(alertActions.warning({iconTitle: faUserMd, icon:faExclamationTriangle, title:"Ops...", message: warningsConstants.ER_PASSWORD_REPEAT}));
                return;
            }
        }

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        userServices.update(token, user).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Editando usuário", iconTitle: faUserMd, icon:faCheckCircle, message: warningsConstants.SC_UPDATE_USER, eventOnClose: this.back}));
            } else {
                dispatch(alertActions.warning({iconTitle: faUserMd, icon:faExclamationTriangle, title:"Editando usuário", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( error =>{
            console.log(error);
            const { dispatch } = this.props;
            const { status, data } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
        });
    }

    back(e) {
        this.setState({redirect: '/usuarios'});
    }
    
    onChange = evt => {
        let user = this.state.user;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        user[evt.target.name] = evt.target.value;
        this.setState({user});
    }

    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    changePassword(e){
        let user = this.state.user;
        user.changePassword = !user.changePassword;
        this.setState({user});
    }

    render() {
        const { user, redirect } = this.state;
        if (redirect) {
            return <Redirect push to={redirect} />;
        }
        const { login, password, rePassword } = user;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <section className="col-sm-12 col-md-12 col-lg-10 col-xl-10">
                        <h2 className="title-page"><FontAwesomeIcon icon={faUserMd} /> Editando Usuário</h2>
                        <hr/>
                        <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                        <div className="card p-3">
                            <div className="card-body">
                                <form onSubmit={this.submitUser}>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                            <TextField value={login} required={true} name="login" onChange={e => this.onChange(e)} respInvalid="Inform o login do usuário" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,60}$/} type="text" placeholder="Login" label="Login do usuário" size="lg" />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-6 col-md-4 col-lg-4  col-xl-4 mb-4">
                                            <TextField value={password} required={true} disabled={!user.changePassword} name="password" respInvalid="A senha deve conter no mínimo 4 digitos." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="senha" size="lg" label="Senha" />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4  col-xl-4 mb-4">
                                            <TextField value={rePassword} required={true} disabled={!user.changePassword} name="rePassword" respInvalid="Repita a senha." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="Repita a senha" size="lg" label="Repita a senha" />
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-4 d-flex align-items-end">
                                            {user.changePassword ? 
                                                <Button type="button" evtClick={this.changePassword} text="Não alterar senha" size="lg" className="btn-fiell w-100" color="primary" icon={faLockOpen}/> : 
                                                <Button type="button" evtClick={this.changePassword} text="Alterar senha" size="lg" className="btn-fiell w-100" color="info" icon={faLock}/>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row d-flex justify-content-center">
                                        <Button type="submit" icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                                        <Button link="/usuarios" outline={true} icon={faTimes} text="Cancelar" color="primary" className="ml-2 " />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(User);