import React from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from "react-redux";
import { authActions } from '../_actions';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        
        const { dispatch } = this.props;
        dispatch(authActions.logout());
    }

    render() {
        return <Redirect to='/login' />
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Logout);