import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import { faStethoscope, faDollarSign, faUsers, faExclamationTriangle, faWrench, faKey, faExclamationCircle, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions } from '../../_actions';
import Card from '../../_components/structure/card';
import { util } from '../../_components/util';
import { attendanceServices, userServices} from '../../_services';
import { warningsConstants} from '../../_constants';
import { mask } from '../../_components/mask';
import Button from '../../_components/forms/button';

class Dashboard extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.state = { 
            economy: "R$ 0,00", attendances: 0, totalBeneficiaries: 0, limitBeneficiaries: 0,
            textSearch: "",
            city: -1, 
            category: -1, 
            speciality: -1,
            procedure: -1,
            cities: [],
            lastCoupon: null,
            listCategories: [],
            listSpecialities: [],
            listProcedures: [],
            placeholderCategories: "Todas categorias",
            placeholderSpecialities: "Todas especialidades",
            placeholderProcedures: "Todos serviços",
            resultSearch: [],
            beneficiaries: [],
            errorSearch: false,
            loadingSearch: false,
            errorScheduling: false,
            loadingScheduling: false,
        };

        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));        
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    userIsActive = () => {
        const { currentUser } = this.props.authentication;
        if (currentUser.status === "4" || currentUser.status === "0") {
            return false;
        }
        return true;
    }

    loadData() {
        const { dispatch, authentication } = this.props;
        const { token } = authentication;

        if (!this.userIsActive()) {
            dispatch(alertActions.clear());
            return;
        }
        
        attendanceServices.getAll(token, { init: "", end: "", page: 1 }).then(res => {
            console.log(res.data.data);
            if (res.data.code === "OK") {
                if (res.data.data.length > 0) {
                    this.setState({ lastCoupon: res.data.data[0] });
                }
            } else {
                this.setState({ error: res.data, lastCoupon: null, loading: false });
            }
            return attendanceServices.getDashboard(token);
        }).then(resp => {
            const {code, data} = resp.data;
            if (code === "OK") {
                this.setState({
                    economy: data.economy,
                    attendances: data.attendances,
                    totalBeneficiaries: data.totalBeneficiaries,
                    limitBeneficiaries: data.limitBeneficiaries
                });
                //dispatch(alertActions.clear());
            } else {
                //dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
                let error = {message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }

            return userServices.getBeneficiaries(token, {
                type: 1,
                page: 1,
                status: true
            });
        }).then(res => {
            const {code, data} = res.data;
            if (code === "OK") {
                let beneficiaries = [];
                data.forEach(ben => {
                    beneficiaries.push({
                        text: ben.name,
                        value: ben.id
                    });
                });
                this.setState({ beneficiaries });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response: false};
                throw error;
            }
            dispatch(alertActions.clear());

            let location = localStorage.getItem('generateGuide') ? localStorage.getItem('generateGuide') : util.getUrlParameter(window.location.href, "location");
            if (location !== "") {
                this.openScheduling({infoExtra: JSON.parse(window.atob(location))});
            }
            localStorage.removeItem('generateGuide');
        }).catch( (error) =>{
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }


    render() {
        const { economy, attendances, lastCoupon, totalBeneficiaries, limitBeneficiaries, redirect } = this.state;

        if (redirect) {
            return <Redirect push to={redirect} />;
        }

        if (!this.userIsActive()) {
            return (
                <div>
                    <section className="m-3">
                        <div className="row justify-content-center">
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <Card title="" className="text-center">
                                    <FontAwesomeIcon icon={faExclamationCircle} color="#1c72bc" size="2x" className=""/>
                                    <h5 className="mt-3 text-muted">Seu cadastro está inativo, verifique a seção de mensalidades ou entre em contato conosco.</h5>
                                    <p className="text-info font-weight-bold">(85) 3371-4377 / 98948-6161</p>
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }

        return (
            <div>
                <section className="m-3">
                    <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                            <Card title={economy} color="#1c72bc">
                                <FontAwesomeIcon icon={faDollarSign} color="#1c72bc" size="2x" className="float-right"/>
                                <h5 className="text-muted">Você já economizou</h5>
                            </Card>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                            <Card title={attendances} color="#0a3563">
                                <FontAwesomeIcon icon={faStethoscope} color="#0a3563" size="2x" className="float-right"/>
                                <h5 className="text-muted">Atendimentos</h5>
                            </Card>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                            <Card title={`${totalBeneficiaries}/${limitBeneficiaries}`} color="#444">
                                <FontAwesomeIcon icon={faUsers} color="#444" size="2x" className="float-right"/>
                                <h5 className="text-muted">Beneficiários</h5>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 d-flex">
                            <Card className="bg-success border-0 shadow flex-fill ">
                                <h3 className="text-white font-weight-bold">Gere seu cupom</h3>
                                <FontAwesomeIcon icon={faStethoscope} color="#fff" size="4x" className="ml-4 mb-4 float-right"/>
                                <span className="text-white font-weight-light mr-4">Encontre o conveniado e ganhe seu desconto</span>
                                <hr className="my-3"/>
                                <Button link="attendance" icon={faStethoscope} text="Gerar cupom" className="btn btn-light" />
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 d-flex">
                            <div className="card flex-fill shadow">
                                {
                                    lastCoupon &&
                                    <div className="card-body d-flex flex-1 justify-content-center align-items-center flex-column">
                                        <h3 className="font-weight-bold mb-3">Último cupom</h3>
                                        <b className="w-100 h4 m-0 border-success py-2 border bg-light rounded-lg font-weight-bold text-success d-block text-center">
                                            {lastCoupon.id}
                                        </b>
                                        <span className="font-weight-light text-muted mt-3 d-block text-center">
                                            Você gerou este cupom: 
                                            <b className="ml-1 font-weight-bold">{mask.datehour(lastCoupon.dateGenerate)}</b>
                                        </span>
                                    </div>
                                }
                                {
                                    !lastCoupon &&
                                    <div className="card-body d-flex flex-1 justify-content-center align-items-center flex-column">
                                        <FontAwesomeIcon icon={faTicketAlt} color="#0a3563" size="4x" className="text-center mb-3"/>
                                        <span className="mb-3 text-center">Você ainda não gerou nenhum cupom.</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3 d-flex">
                            <div className="card rounded-lg flex-fill border-0 shadow">
                                <div className="card-body p-0 rounded-lg" style={{backgroundSize:"cover", backgroundPosition:"center", backgroundImage:`url(banner.png)`, minHeight: 200}}></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Dashboard);