import React from 'react';

export default class InputGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {validation: null};
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    label() {
        if (this.props.label === "" || this.props.label === undefined || this.props.label === false) {
            return false;
        }
        let classLabel = "form-label";
        if (this.props.size === "lg") {
            classLabel += " form-label-lg";
        } else  if (this.props.size === "sm") {
            classLabel += " form-label-sm";
        }
        return (<label className={classLabel} htmlFor={this.props.id}>{this.props.label}</label>)
    }

    onChangeValue(e) {
        if (this.props.pattern !== "" && this.props.pattern !== undefined) {
            if(this.props.pattern.test(e.target.value)){
                this.setState({validation: true});
            } else {
                this.setState({validation: false});
            }
        }
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        e.preventDefault();
    }

    input(value) {
        let classInput = "form-control";
        if (this.props.size === "lg") {
            classInput += " form-control-lg";
        } else  if (this.props.size === "sm") {
            classInput += " form-control-sm";
        }
        
        return (<input onChange={this.onChangeValue} value={value} name={this.props.name} type={this.props.type} className={classInput } id={this.props.id} placeholder={this.props.placeholder} required={this.props.required} />)
    }

    render() {
        const { prepend, append, value, className } = this.props;
        let label = this.label();
        let input = this.input(value);
        return (
            <div className="w-100">
                { label !== false && label }
                <div className={"input-group "+(!className && className)}>
                    {
                        prepend &&
                        <div className="input-group-prepend">
                            { prepend }
                        </div>
                    }
                    {input}
                    {
                        append &&
                        <div className="input-group-append">
                            { append }
                        </div>
                    }
                </div>
            </div>
        );
    }
}