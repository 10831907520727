import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class TextField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {validation: null, inited: false};
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    label() {

        const {size, label } = this.props;

        if (label === "" || label === undefined) {
            return false;
        }
        let classLabel = "";
        if (size === "lg") {
            classLabel += " form-label-lg";
        } else  if (size === "sm") {
            classLabel += " form-label-sm";
        }

        return (<label className={"font-weight-bold d-block "+classLabel} htmlFor={this.props.id}>{this.props.label} {this.props.required && <span className="text-danger">*</span>}</label>)
    }

    onChangeValue(e) {
        const { type } = this.props;
        if (type === "datepicker") {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
            return;
        }
        e.preventDefault();
        e.target.value = this.mask(e.target.value);

        if (this.props.onChange) {
            this.props.onChange(e);
        }

        if (this.props.pattern !== "" && this.props.pattern !== undefined) {
            if(this.props.pattern.test(e.target.value)){
                this.setState({validation: true});
            } else {
                this.setState({validation: false});
            }
        }
    }

    mask(value){
        if (typeof value === "undefined") {
            return value;
        }
        let x;
        switch(this.props.mask){
            case 'number':
                return value.toString().replace(/[^\d]+/g, '')
            case 'date':
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
                return (!x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : ''));
            case 'phone':
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
                if (x[0].substring(2,3) === "9") {
                    return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
                }
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
                return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
            case 'cnpj':  
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
                return (!x[2] ? x[1] : x[1] + '.' + x[2] + (!x[3] ? '' : '.' + x[3] + (!x[4] ? '' : '/'+x[4] + (x[5] ? '-' + x[5] : ''))));
            case 'cpf':
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                return (!x[2] ? x[1] : x[1] + '.' + x[2] + (!x[3] ? '' : '.' + x[3] + (x[4] ? '-' + x[4] : '')));
            case 'cep':
                x = value.toString().replace(/[^\d]+/g, '').match(/(\d{0,5})(\d{0,3})/);
                return (!x[2] ? x[1] : x[1] + '-' + x[2]);
            case 'money':
                x = value.toString().replace(/[^\d]+/g, '');
                if (x === "") {
                    return "";
                }
                if (x.length < 3) {
                    x = parseFloat(x).toLocaleString('pt-BR', { minimumFractionDigits: 0 , style: 'currency', currency: 'BRL' });
                } else {
                    x = parseFloat((x.substring(0, (x.length - 2)))+"."+(x.substring((x.length - 2),x.length))).toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' });
                }
                return x;
            case 'percent':
                x = value.toString().replace(/[^\d]+/g, '');
                if (x === "") {
                    return "";
                }
                if (x.length < 3) {
                    x = parseFloat(x).toLocaleString('pt-BR', { minimumFractionDigits: 0});
                } else {
                    x = parseFloat((x.substring(0, (x.length - 2)))+"."+(x.substring((x.length - 2),x.length))).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                }
                return x;
            default:
                //this.setState({value: value});
                return value;
        }
    }

    input(value) {
        const {disabled, size, validation, name, type, id, placeholder, required, row, idx } = this.props;
        let classInput = "form-control";
        if (size === "lg") {
            classInput += " form-control-lg";
        } else  if (size === "sm") {
            classInput += " form-control-sm";
        }
        if (validation === true) {
            classInput += " is-valid";
        } else if (validation === false) {
            classInput += " is-invalid";
        }
        value = this.mask(value);

        if (type === "datepicker") {
            return ( <div className="d-block w-100"><DatePicker idx={idx} disabled={disabled ? true : false} dateFormat="dd/MM/yyyy" className={classInput} selected={value} name={name} onChange={this.onChangeValue} id={id} placeholder={placeholder} required={required}/></div>)
        }

        if (type === "textarea") {
            return (<textarea idx={idx} disabled={disabled ? true : false} row={row} onChange={this.onChangeValue} value={value} name={name} type={type} className={classInput} id={id} placeholder={placeholder} required={required} />)
        }
        return (<input idx={idx} disabled={disabled ? true : false} onChange={this.onChangeValue} value={value} name={name} type={type} className={classInput} id={id} placeholder={placeholder} required={required} />)
    }

    render() {
        const {value, className, respInvalid, respValid} = this.props;
        let label = this.label();
        let input = this.input(value);

        return (
            <div className={"form-row " + (className !== undefined && className)}>
                { label !== false && label }
                {input}
                <div className="valid-feedback">{respValid}</div>
                <div className="invalid-feedback">{respInvalid}</div>
            </div>
        );
    }
}