import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const proceduresServices = {
    stop,
    getProcedures,
    getProceduresBySpeciality,
    disable,
    insert,
    insertService,
    updateService,
    enable
}

const CancelToken = axios.CancelToken;
let cancel;

function insertService(token, procedure, location){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    procedure.location = location;
    
    
    return axios.post(`${servicesConstants.URL_SERVER}services/create/service.php`, qs.stringify(procedure), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

function updateService(token, procedure, location){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    procedure.location = location;
    return axios.post(`${servicesConstants.URL_SERVER}services/update/service.php?uid=${procedure.id}`, qs.stringify(procedure), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

function enable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}services/update/recycleProcedure.php?uid=${uid}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}services/delete/procedure.php?uid=${uid}`);
}

function insert(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/procedure.php?uid=${uid}`);
}

function getProcedures(filter) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/procedures.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getProceduresBySpeciality(speciality) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/procedures.php?speciality=${speciality}`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
