import React from 'react';
import { connect } from "react-redux";
import { faTicketAlt, faExclamationTriangle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions } from '../../_actions';
import Card from '../../_components/structure/card';
import Button from '../../_components/forms/button';
import TextField from '../../_components/forms/textField';
import { warningsConstants } from '../../_constants';
import { ticketServices } from '../../_services';

class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.state = {ticket: "", loading: false};
        dispatch(alertActions.clear());


        this.submitTicket = this.submitTicket.bind(this);
        this.onChange = this.onChange.bind(this);
    }


    submitTicket(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { ticket } = this.state;
        dispatch(alertActions.clear());


        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        ticketServices.validation(token, ticket).then(res => {
            if (res.data.code === "OK") {
                this.setState({ticket: ""});
                dispatch(alertActions.success({title:"Validando Cupom", iconTitle: faTicketAlt, icon:faCheckCircle, message: warningsConstants.SC_VALIDATION_TICKET}));
            } else {
                dispatch(alertActions.warning({iconTitle: faTicketAlt, icon:faExclamationTriangle, title:"Validando Cupom", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( error =>{
            console.log(error);
            const { dispatch } = this.props;
            const { status, data } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Validando Cupom", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
        });
    }

    onChange = evt => {
        let ticket = this.state.ticket;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        ticket = evt.target.value;
        this.setState({ticket});
    }

    render() {
        const {ticket} = this.state;
        return (
            <div>
                <section className="m-3">
                    <div className="row justify-content-center align-items-center ">
                        <div className="col-sm-12 col-md-6 col-xl-6 col-lg-5 my-5">
                            <Card title={(<div><FontAwesomeIcon icon={faTicketAlt}/> Validação de Cupom</div>)}>
                                <form className="m-3" onSubmit={this.submitTicket}>
                                    <TextField value={ticket} required={true} name="name" onChange={e => this.onChange(e)} type="number" placeholder="Código do cupom" label="Código do cupom" size="lg" />
                                    <Button type="submit" icon={faCheckCircle} size="lg" text="Validar" color="success" className="mt-3 w-100"/>
                                </form>
                            </Card>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Dashboard);