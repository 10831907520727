import axios from 'axios/dist/axios';
import  { servicesConstants }  from '../_constants';

export const locationServices = {
    get,
    enable,
    disable,
    stop,
    update,
    insert,
    getAll,
}

const CancelToken = axios.CancelToken;
let cancel;
function get(token, uid) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}services/read/serviceLocations.php?uid=${uid}`);
}

function enable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}users-recycle/${uid}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}services/delete/serviceLocation.php?uid=${uid}`);
}

function update(token, location){
   /* axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/update/serviceLocation.php?uid=${location.id}`, qs.stringify(location), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    },);*/

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let formData = new FormData();
    formData.append("image", location.image);
    formData.append("id", location.id);
    formData.append("cep", location.cep);
    formData.append("name", location.name);
    formData.append("number", location.number);
    formData.append("street", location.street);
    formData.append("district", location.district);
    formData.append("city", JSON.stringify(location.city));
    formData.append("state", JSON.stringify(location.state));
    formData.append("services", JSON.stringify(location.services));
    formData.append("complement", location.complement);
    formData.append("phone1", location.phone1);
    formData.append("phone2", location.phone2);
    formData.append("phone3", location.phone3);
    return axios.post(`${servicesConstants.URL_SERVER}services/update/serviceLocation.php?uid=${location.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function insert(token, location){
    /*axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/serviceLocation.php`, qs.stringify(user), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });*/

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let formData = new FormData();
    formData.append("image", location.image);
    formData.append("cep", location.cep);
    formData.append("name", location.name);
    formData.append("number", location.number);
    formData.append("street", location.street);
    formData.append("district", location.district);
    formData.append("city", JSON.stringify(location.city));
    formData.append("state", JSON.stringify(location.state));
    formData.append("services", JSON.stringify(location.services));
    formData.append("complement", location.complement);
    formData.append("phone1", location.phone1);
    formData.append("phone2", location.phone2);
    formData.append("phone3", location.phone3);
    return axios.post(`${servicesConstants.URL_SERVER}services/create/serviceLocation.php`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


function getAll(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/serviceLocations.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}


function stop(message){
    if (cancel) {
        cancel(message);
    }
}
