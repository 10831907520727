import { authConstants, warningsConstants } from '../_constants';
import { authServices } from '../_services';
import { alertActions } from '../_actions';
import { util } from '../_components/util';
import { history } from '../_store/history';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const authActions = {
    loginAgreed,
    loginClient,
    getData,
    logout
};

function loginClient(cpf, birthday) {
    
    let location = util.getUrlParameter(window.location.href, "location");
    if (location !== "") {
        localStorage.setItem('generateGuide', location);
    }

    return dispatch => { 
        authServices.authenticateClient(cpf, birthday).then(resp => { 
            if (resp.token && resp.user) {
                localStorage.setItem('tokenSimplevida',resp.token);
                localStorage.setItem('userSimplevida', JSON.stringify(resp.user));
                dispatch(success(resp.token, resp.user));

                let newClient = localStorage.getItem('newClient') ? localStorage.getItem('newClient') : util.getUrlParameter(window.location.href, "newClient");
                if (newClient !== "") {
                    history.push('/mensalidades');
                    return;
                }

                history.push('/');
                return;
            }
            throw new Error(warningsConstants.ER_DEFAULT);
        }).catch(error => {
            dispatch(failure(error));
            try {
                dispatch(alertActions.error({code: error.response.data.code, message: error.response.data.message ? error.response.data.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            } catch (e) {
                dispatch(alertActions.error({ code: error.code, message: error.message ? error.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            }
        });
    };

    function success(token, currentUser) { return { type: authConstants.LOGIN_SUCCESS, token, currentUser } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function loginAgreed(email, password) {

    return dispatch => { 
        authServices.authenticateAgreed(email, password).then(resp => { 
            if (resp.token && resp.user) {
                localStorage.setItem('tokenSimplevida',resp.token);
                localStorage.setItem('userSimplevida', JSON.stringify(resp.user));
                dispatch(success(resp.token, resp.user));

                let newAgreed = localStorage.getItem('newAgreed') ? localStorage.getItem('newAgreed') : util.getUrlParameter(window.location.href, "newAgreed");
                if (newAgreed !== "") {
                    history.push('/locais');
                    return;
                }

                history.push('/');
                return;
            }
            throw new Error(warningsConstants.ER_DEFAULT);
        }).catch(error => {
            dispatch(failure(error));
            try {
                dispatch(alertActions.error({code: error.response.data.code, message: error.response.data.message ? error.response.data.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            } catch (e) {
                dispatch(alertActions.error({ code: error.code, message: error.message ? error.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            }
        });
    };

    function success(token, currentUser) { return { type: authConstants.LOGIN_SUCCESS, token, currentUser } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function getData(token) {
    return dispatch => { 
        authServices.getDataUser(token).then(resp => { 
            if (resp.data.user) {
                localStorage.setItem('userSimplevida', JSON.stringify(resp.data.user));
                dispatch(success(token, resp.data.user));
                return;
            }
            throw new Error(warningsConstants.ER_DEFAULT);
        }).catch(error => {
            dispatch(failure(error));
            try {
                dispatch(alertActions.error({code: error.response.data.code, message: error.response.data.message ? error.response.data.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            } catch (e) {
                dispatch(alertActions.error({ code: error.code, message: error.message ? error.message : warningsConstants.ER_DEFAULT, icon:faExclamationTriangle}));
            }
        });
    };

    function success(token, currentUser) { return { type: authConstants.USER_SUCCESS, token, currentUser } }
    function failure(error) { return { type: authConstants.USER_FAILURE, error } }
}

function logout() {
    authServices.logout();
    return { type: authConstants.LOGOUT };
}