import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default class Select extends React.Component {

    constructor(props) {
        super(props);
        this.state = {validation: null};
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    label() {

        const {size, label } = this.props;

        if (label === "" || label === undefined) {
            return false;
        }
        let classLabel = "form-label d-block";
        if (size === "lg") {
            classLabel += " form-label-lg";
        } else  if (size === "sm") {
            classLabel += " form-label-sm";
        }

        return (<label className={"font-weight-bold "+classLabel} htmlFor={this.props.id}>{this.props.label} {this.props.required && <span className="text-danger">*</span>}</label>)
    }

    onChangeValue(e) {
        e.preventDefault();
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    input(value) {
        const {disabled, name, id, placeholder, options, size, idx } = this.props;

        let classInput = "";
        if (size === "lg") {
            classInput += " select-lg";
        } else  if (size === "sm") {
            classInput += " select-sm";
        }
        value = (value === null ? false : value);
        return (
        <select disabled={disabled ? true : false} className={classInput} value={value} onChange={this.onChangeValue} name={name} id={id} idx={idx}>
            {placeholder && <option value={false}>{placeholder}</option>}
            { 
                options.map( (item, i) => (
                    <option key={i} value={item.value}>{item.text}</option>
                ))
            }
        </select>
        )

    }

    render() {
        const {value, className} = this.props;
        let label = this.label();
        let input = this.input(value);

        return (
            <div className={"box-select "+(className ? className : "")}>
                { label !== false && label }
                <div className="field-select d-flex align-items-center">
                    {input}
                    <FontAwesomeIcon className="icon-select" icon={faChevronDown} color="#FA5798"/>
                </div>
            </div>
        );
    }
}