import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class InputFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {file: false, previewFile: false, nameFile: '', progress: false};
        this.onChange = this.onChange.bind(this);
        this.openFile = this.openFile.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.onLoading = this.onLoading.bind(this);
    }

    label() {
        if (this.props.label === "" || this.props.label === undefined) {
            return false;
        }
        return (<label className="font-weight-bold" htmlFor={this.props.id}>{this.props.label}</label>)
    }

    openFile(e) {
        this.inputElement.click();
    }

    onChange(e) {
        const file = e.target.files[0];
        this.reader = new FileReader();
        this.reader.onloadend = this.onComplete;
        this.reader.onprogress = this.onLoading;
        if (file) {
            this.setState({file, nameFile: file.name});
            this.reader.readAsDataURL(file);
        } 
    }

    onLoading(e){
        const { onLoading } = this.props;
        let percent = Math.round(e.loaded / e.total) * 100;
        this.setState({progress: percent});
        if (onLoading) {
            onLoading(e);
        }
    }

    onComplete(e) {
        const { onComplete } = this.props;
        const { file } = this.state;
        let previewFile = this.reader.result;
        this.setState({previewFile, progress: false});
        if (onComplete) {
            onComplete(file, previewFile);
        }
    }

    render() {
        const { text, iconButton, className, size, color, typeFile, photo, rounded } = this.props;
        let {  width, height } = this.props;
        let { previewFile, progress } = this.state;

        let classBtn = "btn "+(className ? className: '')
        if (size === "lg") {
            classBtn += " btn-lg";
        } else  if (size === "sm") {
            classBtn += " btn-sm";
        }
        
        if (!color) {
            classBtn += " btn-outline-primary";
        } else {
            classBtn += " btn-"+color;
        }
        let viewFile = false;
        let acceptFiles = '';
        let classRounded = "rounded-lg";

        if (rounded) {
            width = height = 140;
            classRounded = "rounded-circle";
        }
        
        if (typeFile === "image") {
            acceptFiles = "image/*";
            if (previewFile) {
                viewFile = (<img alt="Imagem para upload" width={width} height={height} className={(classRounded)+" d-block  shadow border border-success mb-3"} src={previewFile}/>);
            } else if (photo){
                viewFile = (<img alt="Imagem para upload" width={width} height={height} className={(classRounded)+" d-block shadow border border-success mb-3"} src={photo}/>);
            } else {
                viewFile = (<div style={ {width: `${width}px`, height: `${height}px`} } className={(classRounded)+" text-center d-flex align-items-center shadow-sm border border-light mb-3"}>
                    <div className="w-100 m-0 text-muted row justify-content-center">
                        <FontAwesomeIcon className="col-12" size="3x" icon={iconButton}/>
                    </div>
                </div>);
            }
        }

        return (
            <div className={"text-center "+ (viewFile ? "d-block" : "d-inline")}>
                {viewFile && viewFile}       
                {   progress ?
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{width: progress+"%"}}>{progress+"%"}</div>
                    </div> : 
                    <button type="button" onClick={this.openFile} className={classBtn}>
                        {iconButton && <FontAwesomeIcon icon={iconButton}/>} {text}
                        <input onChange={this.onChange} type="file" ref={input => this.inputElement = input} accept={acceptFiles} className="hidden file-upload" />
                    </button>
                }
            </div>
        );
    }
}