import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import Alert from '../../_components/structure/alert';
import Button from '../../_components/forms/button';
import InputGroup from '../../_components/forms/inputGroup';
import ListItems from '../../_components/structure/listItems';
import Pagination from '../../_components/structure/pagination';

import { alertConstants, warningsConstants, servicesConstants } from '../../_constants';
import { alertActions } from '../../_actions';
import { userServices } from '../../_services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faExclamationTriangle, faPlusCircle, faInfoCircle, faCheckCircle, faSearch, faUserMd, faKey } from '@fortawesome/free-solid-svg-icons';


class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {users:[], currentPage: 1, lastPage: 1, search: '', error: false, loading: true, status: true, redirect: false};
        setTimeout(() =>{
            this.loadUsers(1);
        }, 300);

        this.loadUsers = this.loadUsers.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.openDisabled = this.openDisabled.bind(this);
    }

    loadUsers(page) {
        const { token, currentUser } = this.props.authentication;
        const { search, status } = this.state;
        const { dispatch } = this.props;

        this.setState({ loading: true });
        
        userServices.getAll(token, {
            search,
            type: 1,
            page,
            status
        }).then(res => {
            if (res.data.code === "OK") {
                let users = [];
                res.data.data.forEach(user => {
                    if (currentUser.code === user.id) {
                        users.push({
                            key: user.id,
                            code: user.code,
                            infoMain: 0,
                            info:[
                                { name:"Nome", value: user.name },
                                { name:"Login", value: user.login }
                            ],
                            buttons: [
                                {text:'', icon:faPencilAlt, color:'light', link:"usuarios/editar/"+user.id}
                            ]
                        });
                        return;
                    }
                    if (currentUser.isHolder) {
                        users.push({
                            key: user.id,
                            code: user.code,
                            infoMain: 0,
                            info:[
                                { name:"Nome", value: user.name },
                                { name:"Login", value: user.login }
                            ],
                            buttons: [
                                {text:'', icon:faPencilAlt, color:'light', link:"usuarios/editar/"+user.id},
                                {text:'', icon:faTrash, color:'danger', fn:this.openDisabled}
                            ]
                        });
                    }
                });
                if (users.length > 0) {
                    this.setState({ users, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: false, loading: false});
                } else {
                    this.setState({ users, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: {
                        message: warningsConstants.WA_USERS_NOT_FOUND
                    }, loading: false});
                }
            } else {
                this.setState({ error: res.data, users: [], currentPage: 1, lastPage: 1, loading: false });
            }
            dispatch(alertActions.clear());
        }).catch( error => {
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, users: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    componentWillUnmount() {
        userServices.stop(servicesConstants.CANCEL_REQUEST);
    }

    openDisabled(params){
        const { dispatch } = this.props;
        const { token } = this.props.authentication;

        dispatch(alertActions.confirm({
            iconTitle: faTrash,
            title: "Desabilitar usuário",
            icon: faInfoCircle,
        message: (<div>{"Tem certeza que deseja desabilitar este usuário "}<b className="text-pink">({params.info[params.infoMain].value})</b>{" ?"}</div>),
            eventPrimary: (e, close) => {
                //Exibe o dialog  de carregamento
                dispatch(alertActions.loading({message: "Desabilitando..."}));
                //Faz a requisição ao servidor
                userServices.disable(token, params.key).then(res => {
                    //Se houve a remoção do usuário
                    if (res.data.code === "OK") {
                        this.setState({ loading: true });
                        //Exibe o dialog de sucesso
                        dispatch(alertActions.success({title:"Desabilitando usuário", iconTitle: faTrash, icon:faCheckCircle, message: warningsConstants.SC_DISABLE_USER}));
                        //Atualiza a lista de usuário
                        this.loadUsers(1);
                    } else {
                        dispatch(alertActions.warning({iconTitle: faTrash, icon:faExclamationTriangle, title:"Desabilitando usuário", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
                    }
                }).catch( error =>{
                    console.log(error);
                    dispatch(alertActions.error({title:"Desabilitando usuário", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
                });
            }
        }));
    }

    onChangeSearch(evt) {
        this.setState({ search: evt.target.value });
    }

    submitSearch(e) {
        e.preventDefault();
        this.loadUsers(1);
    }

    render() {
        const { loading, error, users, lastPage, currentPage, redirect} = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        return (
            <div>
                <section className="m-3">
                    <h2 className="title-page"><FontAwesomeIcon icon={faUserMd} /> Usuários</h2>
                    <hr/>
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Button link="/usuarios/novo" text={"Novo usuário"} className="btn-fiell" icon={faPlusCircle} color={"success"}/>
                        <form onSubmit={ this.submitSearch } className="d-flex w-50">
                            <InputGroup alue={this.state.search} name="search" onChange={e => this.onChangeSearch(e)} type="text" placeholder="Pesquise por usuarios" label={false} append={<Button type="submit" className="btn-fiell" text={"Pesquisar"} icon={faSearch} color={"primary"}/>}/>
                        </form>
                    </div>
                    <div className="card">
                        <div className="card-body">
                        { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                        { (!loading && error) && <Alert text={error.message} type={alertConstants.WARNING}></Alert> }
                        { (!loading && !error) &&  <ListItems items={users}/> }
                        </div>
                    </div>
                </section>
                <footer>
                    { (!loading && !error && lastPage > 1) && 
                        <Pagination fnChangePage={this.loadUsers} currentPage={currentPage} lastPage={lastPage}/>
                    }   
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Users);