import axios from 'axios/dist/axios';
import { servicesConstants } from '../_constants';

export const citiesServices = {
    getAllCities,
    getCities,
    getStates,
    getStreet,
    searchCEP,
    stop
}

const CancelToken = axios.CancelToken;
let cancel;
function getStates() {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/uf.php`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

async function searchCEP(cep) {
    /*const options = {
        method: 'GET',
        url: `http://viacep.com.br/ws/${cep.replace(/[^\d]+/g, '')}/json/`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);*/
    const options = {
        method: 'GET',
        baseURL: "http://pafmaster.hfsolucoes.com.br/",
        url: `cep.php?cep=${cep.replace(/[^\d]+/g, '')}`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getAllCities() {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/cities.php?uf=all`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getCities(state) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/cities.php?uf=${state}`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getStreet(token, cep) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/street.php?cep=${cep.toString().replace(/[^\d]+/g, '')}`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}


function stop(message) {
    if (cancel) {
        cancel(message);
    }
}
