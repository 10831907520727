import  { notificationConstants }  from '../_constants';

export default function notification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.SUCCESS:
      return {
        type: 'notify-success',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.INFO:
      return {
        type: 'notify-info',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.WARNING:
      return {
        type: 'notify-warning',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.DANGER:
      return {
        type: 'notify-danger',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.CLEAR:
      return {};
    default:
      return state
  }
}