import axios from 'axios/dist/axios';
import  { servicesConstants }  from '../_constants';

export const specialitiesServices = {
    stop,
    getSpecialities,
    getSpecialitiesByCategory
}

const CancelToken = axios.CancelToken;
let cancel;

function getSpecialities(filter) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/specialities.php`,
        params: filter,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getSpecialitiesByCategory(category) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/specialities.php?category=${category}`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}


function stop(message){
    if (cancel) {
        cancel(message);
    }
}
