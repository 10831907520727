import React from 'react';
import { connect } from "react-redux";
import TextField from '../../../_components/forms/textField';
import { proceduresServices } from '../../../_services';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../../../_components/forms/checkbox';
import Button from '../../../_components/forms/button';
import Select from '../../../_components/forms/select';
import { warningsConstants, alertConstants } from '../../../_constants';
import Alert from '../../../_components/structure/alert';

class Service extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            service: {procedure: false, speciality: false, id:false, status:true, showDiscount:false, percentDiscount: "0", value: "0", valueDiscount: "0", valueTotal: "0"}, 
            typeDiscount: 1,
            specialities: props.specialities, 
            procedures: props.procedures, 
            error: false, 
            loading: false,
            submitted: false
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        setTimeout(() =>{
            if (props.service) {
                let serv = props.service;
                serv.valueDiscount = this.formatNumber(props.service.valueDiscount);
                serv.value = this.formatNumber(props.service.value);
                serv.valueTotal = this.formatNumber(props.service.valueTotal);
                this.setState({service: props.service});
            }
        }, 300);
    }

    formatNumber = (value) => {
        let v = value.toString().replace(/\D/g, '');
        if (v.length === 0) {
            v = "";
        }else if (v.length === 1) {
            v = "0,0"+v;
        } else if (v.length === 2){
            v = "0,"+v;
        } else {
            v = parseFloat(v.substring(0, v.length-2) +"." + v.substring(v.length-2,v.length)).toLocaleString('pt-BR', { minimumFractionDigits: 2});
        }
        return v;
    }

    onChange = (e) => {
        e.preventDefault();
        let { service, typeDiscount } = this.state;
        if (e.target.name !== "typeDiscount") {

            if (e.target.name === "showDiscount") {
                service[e.target.name] = e.target.checked;
            } else if (e.target.name === "valueDiscount") {
                let v = e.target.value.toString().replace(/\D/g, '');
                if (v.length === 0) {
                    v = "";
                }else if (v.length === 1) {
                    v = "0,0"+v;
                } else if (v.length === 2){
                    v = "0,"+v;
                } else {
                    v = parseFloat(v.substring(0, v.length-2) +"." + v.substring(v.length-2,v.length)).toLocaleString('pt-BR', { minimumFractionDigits: 2});
                }
                service[e.target.name] = v;
            } else {
                service[e.target.name] = e.target.value;
            }
        } else {
            this.setState({typeDiscount: parseInt(e.target.value)});
            typeDiscount = parseInt(e.target.value);
        }

        if (e.target.name === "valueDiscount" || e.target.name === "value" || e.target.name === "typeDiscount") {
            var p = parseFloat(service.value.replace("R$","").replace(/\./g,"").replace(",","."));
            var disc = parseFloat(service.valueDiscount.replace("R$","").replace(/\./g,"").replace(",","."));
            disc = isNaN(disc) ? 0 : disc;
            p = isNaN(p) ? 0 : p;

            console.log(p, disc, (p - disc));

            //Desconto em dinheiro
            if (typeDiscount === 1) {
                service.valueTotal = (p - disc).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
            } else { //Desconto em porcentagem
                var dTotal = (p - (disc*p/100));
                service.valueTotal = (dTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }));
            }
        }

        console.log(service);
        this.setState({service});
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { service } = this.state;
        const { authentication } = this.props;
        const { token } = authentication;
        const { procedure, speciality } = service;
        if (speciality === "" || speciality === "false" || !speciality) {
            this.setState({error: warningsConstants.ER_SPECIALITY_NOT_SELECTED});
            return;
        } 
        if (procedure === "" || procedure === "false" || !procedure) {
            this.setState({error: warningsConstants.ER_PROCEDURE_NOT_SELECTED});
            return;
        } 
        this.setState({ error: false });

        if (this.props.onSave) {
            let { service } = this.state;
            var p = parseFloat(service.value.replace("R$","").replace(/\./g,"").replace(",","."));
            var total = parseFloat(service.valueTotal.replace("R$","").replace(/\./g,"").replace(",","."));
            service.valueDiscount = (p - total).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            const resp = this.props.onSave(service);
            this.setState({error: resp});
            return;
        }

        if (this.props.onSaveEdit) {
            this.setState({ loading: warningsConstants.LD_DEFAULT });

            proceduresServices.updateService(token, service, this.props.location).then(res => {
                this.setState({ loading: false });
                if (res.data.code === "OK") {
                    if (this.props.onSaveEdit) {
                        this.props.onSaveEdit(service);
                    }
                } else {
                    this.setState({error: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT});
                }
            }).catch( error => {
                console.log(error);
                const { response } = error;
                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message) {
                            this.setState({loading: false, error: data.message});
                            return;
                        }
                    }
                }
                this.setState({loading: false, error: error.message ? error.message : warningsConstants.ER_DEFAULT});
            });
        }

        if (this.props.onSaveNew) {
            this.setState({ loading: warningsConstants.LD_DEFAULT });
            proceduresServices.insertService(token, service, this.props.location).then(res => {
                this.setState({ loading: false });
                if (res.data.code === "OK") {
                    if (this.props.onSaveNew) {
                        this.props.onSaveNew(service);
                    }
                } else {
                    this.setState({error: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT});
                }
            }).catch( error => {
                console.log(error);
                const { response } = error;
                if (response) {
                    const { data } = response;
                    if (data) {
                        if (data.message) {
                            this.setState({loading: false, error: data.message});
                            return;
                        }
                    }
                }
                this.setState({loading: false, error: error.message ? error.message : warningsConstants.ER_DEFAULT});
            });
        }
    }

    render() {
        const { onCancel } = this.props;
        const { service, specialities, procedures, error, loading } = this.state;
        return  (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-12 mb-3">
                    <Select required options={specialities} value={service.speciality} name="speciality" onChange={e => this.onChange(e)} placeholder="Selecione a especialidade" label="Tipo de especialidade" />
                </div>
                <div className="col-12 mb-4">
                    <Select required options={procedures} value={service.procedure} name="procedure" onChange={e => this.onChange(e)} placeholder="Selecione o procedimento" label="Tipo de procedimento" />
                </div>
                <div className="col-12 mb-3">
                    <Checkbox onChange={this.onChange} id="showDiscount" checked={service.showDiscount} name="showDiscount" label="Exibir somente valor do desconto em porcentagem" />
                </div>
                {
                    !service.showDiscount &&
                    <div className="col-4 mb-3">
                        <TextField onChange={this.onChange} mask="money" value={service.value} name="value" type="text" placeholder="R$ 0,00" label="Valor sem Desconto" />
                    </div>
                }
                {
                    !service.showDiscount &&
                    <div className="col-4 mb-3">
                        <div className="form-row ">
                            <label className="font-weight-bold d-block">Valor do desconto<span className="text-danger">*</span></label>
                            <div className="input-group">
                                <select onChange={this.onChange} name="typeDiscount" className="custom-select col-5" >
                                    <option value="1">R$</option>
                                    <option value="2">%</option>
                                </select>
                                <input className="form-control col-7" onChange={this.onChange} value={service.valueDiscount} placeholder="R$ 0,00" mask="money" name="valueDiscount" type="text" required/>
                            </div>
                        </div>
                    </div>
                }
                {
                    !service.showDiscount &&
                    <div className="col-4 mb-3">
                        <TextField disabled mask="money" value={service.valueTotal} type="text" placeholder="R$ 0,00" label="Valor com Desconto" />
                    </div>
                }
                {
                    service.showDiscount &&
                    <div className="col-4 mb-3">
                        <div className="form-row ">
                            <label className="font-weight-bold d-block">Valor do desconto<span className="text-danger">*</span></label>
                            <div className="input-group">
                                <input className="form-control col-7" onChange={this.onChange} value={service.percentDiscount} placeholder="0,00%" mask="percent" name="percentDiscount" type="text" required/>
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-12 mb-3">
                    <div className="mt-4 d-flex justify-content-end">
                        <Button type="submit" icon={faCheck} color="success" text="Salvar"/>
                        <Button evtClick={onCancel} type="button" icon={faTimes} className="ml-3" color="danger" text="Cancelar"/>
                    </div>
                </div>
                { loading && (<div className="col-12 mb-3"><Alert text={loading} type={alertConstants.LOADING}/></div>) }
                { error && (<div className="col-12 mb-3"><Alert text={error} type={alertConstants.WARNING}/></div>) }
            </form>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};
export default connect(mapStateToProps)(Service);