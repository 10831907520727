import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import Alert from '../../_components/structure/alert';
import Button from '../../_components/forms/button';
import InputGroup from '../../_components/forms/inputGroup';
import ListItems from '../../_components/structure/listItems';
import Pagination from '../../_components/structure/pagination';

import { util } from '../../_components/util';
import { alertConstants, warningsConstants, servicesConstants } from '../../_constants';
import { alertActions } from '../../_actions';
import { locationServices, specialitiesServices, proceduresServices } from '../../_services';
import Service from './new/service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faExclamationTriangle, faPlusCircle, faInfoCircle, faCheckCircle, faSearch, faKey, faBuilding, faStethoscope } from '@fortawesome/free-solid-svg-icons';


class Locations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            locations:[], currentPage: 1, lastPage: 1, search: '', error: false, loading: true, status: true, redirect: false,
            listSpecialities: [],
            listProcedures: [],
            procedures: [],
        };
        setTimeout(() =>{
            this.loadSpecialities();
        }, 300);

        this.loadLocations = this.loadLocations.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.openDisabled = this.openDisabled.bind(this);
        this.openNewProcedure = this.openNewProcedure.bind(this);
        
    }

    loadLocations(page) {
        const { token } = this.props.authentication;
        const { search, status } = this.state;
        const { dispatch } = this.props;

        this.setState({ loading: true });
        
        locationServices.getAll(token, {
            search,
            type: 1,
            page,
            status
        }).then(res => {
            let idLocation = null;
            if (res.data.code === "OK") {
                let locations = [];
                res.data.data.forEach(location => {
                    idLocation = location.id;
                    locations.push({
                        key: location.id,
                        infoMain: 0,
                        info:[
                            { name:"Nome", value: location.name },
                            { name:"Endereço", value: `${location.street}, ${location.number}, ${location.district}, ${location.city}-${location.state}`},
                            { align:"right", value: <Button evtClick={{fn: this.openNewProcedure, params:location.id }} size="sm" text="Adicionar Serviço" icon={faStethoscope} color="info"/> },
                        ],
                        buttons: [
                            {text:'', icon:faPencilAlt, color:'light', link:"locais/editar/"+location.id},
                            {text:'', icon:faTrash, color:'danger', fn:this.openDisabled}
                        ]
                    });
                });
                if (locations.length > 0) {
                    this.setState({ locations, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: false, loading: false});
                } else {
                    this.setState({ locations, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: {
                        message: warningsConstants.WA_SERVICE_LOCATION_NOT_FOUND
                    }, loading: false});
                }
                dispatch(alertActions.clear());


                let newAgreed = localStorage.getItem('newAgreed') ? localStorage.getItem('newAgreed') : util.getUrlParameter(window.location.href, "newAgreed");
                if (newAgreed !== "") {
                    dispatch(alertActions.success({
                        message: (
                            <div>
                                <h2>Seja Bem Vindo</h2>
                                <p>
                                    Para concluirmos, você precisa só adicionar os serviços que oferece de acordo com o local de atendimento.
                                </p>
                            </div>
                        ),
                        eventOnClose: (() => { 
                            localStorage.removeItem('newAgreed');
                            if (idLocation !== null) {
                                setTimeout(() =>{
                                    this.openNewProcedure(idLocation);
                                }, 300);
                            }
                        })
                    }));
                }

            } else {
                this.setState({ error: res.data, locations: [], currentPage: 1, lastPage: 1, loading: false });
            }
        }).catch( error => {
            console.log(error);
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, locations: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    componentWillUnmount() {
        locationServices.stop(servicesConstants.CANCEL_REQUEST);
    }

    openDisabled(params){
        const { dispatch } = this.props;
        const { token } = this.props.authentication;

        dispatch(alertActions.confirm({
            iconTitle: faTrash,
            title: "Desabilitar local de atendimento",
            icon: faInfoCircle,
            message: (<div>{"Tem certeza que deseja desabilitar este local de atendimento "}<b className="text-pink">({params.info[params.infoMain].value})</b>{" ?"}</div>),
            eventPrimary: (e, close) => {
                //Exibe o dialog  de carregamento
                dispatch(alertActions.loading({message: "Desabilitando..."}));
                //Faz a requisição ao servidor
                locationServices.disable(token, params.key).then(res => {
                    //Se houve a remoção do local de atendimento
                    if (res.data.code === "OK") {
                        this.setState({ loading: true });
                        //Exibe o dialog de sucesso
                        dispatch(alertActions.success({title:"Desabilitando local de atendimento", iconTitle: faTrash, icon:faCheckCircle, message: warningsConstants.SC_DISABLE_SERVICE_LOCATION}));
                        //Atualiza a lista de local de atendimento
                        this.loadLocations(1);
                    } else {
                        dispatch(alertActions.warning({iconTitle: faTrash, icon:faExclamationTriangle, title:"Desabilitando local de atendimento", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
                    }
                }).catch( error =>{
                    console.log(error);
                    dispatch(alertActions.error({title:"Desabilitando local de atendimento", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
                });
            }
        }));
    }

    onChangeSearch(evt) {
        this.setState({ search: evt.target.value });
    }

    submitSearch(e) {
        e.preventDefault();
        this.loadLocations(1);
    }




    /******** ESPECIALIDADES ********/
    openNewProcedure = (idLocation) => {
        console.log(idLocation);
        const { dispatch } = this.props;
        const { listSpecialities, listProcedures } = this.state;
        
        dispatch(alertActions.dialog({
            iconTitle: faStethoscope,
            title: "Novo Serviço",
            content: (
                <Service 
                    onSaveNew={this.onSaveNewProcedure} 
                    location={idLocation}
                    onCancel={()=>{
                        const { dispatch } = this.props;
                        dispatch(alertActions.clear());
                    }} 
                    specialities={listSpecialities}
                    procedures={listProcedures}
                    />
                ),
        }));
    }
    onSaveNewProcedure = (speciality) => {
        const { dispatch } = this.props;
        dispatch(alertActions.success({title:"Novo serviço", iconTitle: faStethoscope, icon:faCheckCircle, message: warningsConstants.SC_NEW_SERVICE}));
        return false;
    }
    loadSpecialities() {
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        this.setState({ loading: true });
        specialitiesServices.getSpecialities().then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listSpecialities = [];
                
                for (var i = 0;i<data.length;i++) {
                    listSpecialities.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                
                if (data.length > 0) {
                    this.setState({ listSpecialities });
                } else {
                    this.setState({  error: { message: warningsConstants.WA_CATEGORY_NOT_FOUND }, loading: false});
                }
            } else {
                this.setState({ error: res.data, loading: false });
            }

            return proceduresServices.getProcedures();
        }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listProcedures = [];
                
                for (var i = 0;i<data.length;i++) {
                    listProcedures.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                
                if (data.length > 0) {
                    this.setState({ listProcedures, loading: false  });
                } else {
                    this.setState({  error: { message: warningsConstants.WA_CATEGORY_NOT_FOUND }, loading: false});
                }
            } else {
                this.setState({ error: res.data, loading: false });
            }

            this.loadLocations(1);
        }).catch( error =>{
            console.log(error);
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, loading: false });
        }); 
    }
    /******** ESPECIALIDADES ********/




    render() {
        const { loading, error, locations, lastPage, currentPage, redirect} = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        return (
            <div>
                <section className="m-3">
                    <h2 className="title-page"><FontAwesomeIcon icon={faBuilding} /> Locais de Atendimento</h2>
                    <hr/>
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Button link="/locais/novo" text={"Novo local"} icon={faPlusCircle} color={"success"}/>
                        <form onSubmit={ this.submitSearch } className="d-flex w-50">
                            <InputGroup alue={this.state.search} name="search" onChange={e => this.onChangeSearch(e)} type="text" placeholder="Pesquise por locais" label={false} append={<Button type="submit" text={"Pesquisar"} icon={faSearch} color={"primary"}/>}/>
                        </form>
                    </div>
                    <div className="card">
                        <div className="card-body">
                        { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                        { (!loading && error) && <Alert text={error.message} type={alertConstants.WARNING}></Alert> }
                        { (!loading && !error) &&  <ListItems items={locations}/> }
                        </div>
                    </div>
                </section>
                <footer>
                    { (!loading && !error && lastPage > 1) && 
                        <Pagination fnChangePage={this.loadLocations} currentPage={currentPage} lastPage={lastPage}/>
                    }   
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Locations);