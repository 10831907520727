export const mask = {
    money: function(val){   
        if (val === undefined) {
            return "";
        }             
        return val.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
    },
    date: function(val){
        var dt1 = new Date(val);
        var d = new Date(dt1.valueOf() + (( dt1.getTimezoneOffset()/60 - 3) * 3600000));
        return (d.getDate() < 10 ? "0"+d.getDate() : d.getDate()) + "/" + ((d.getMonth() + 1) < 10 ? "0"+(d.getMonth() + 1) : (d.getMonth() + 1)) + "/" + d.getFullYear();
    },
    datehour: function(val){
        if (val) {
            return this.date(val) + " " + this.hour(val);
        }
        return '--';
    },
    hour: function(val){
        var dt1 = new Date(val);
        var d = new Date(dt1.valueOf() + (( dt1.getTimezoneOffset()/60 - 3) * 3600000));
        return (d.getHours() < 10 ? "0"+d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes());
    },
    monthName: function(n){
        switch(n){
            case 1:
                return "Janeiro";
            case 2:
                return "Fevereiro";
            case 3:
                return "Março";
            case 4:
                return "Abril";
            case 5:
                return "Maio";
            case 6:
                return "Junho";
            case 7:
                return "Julho";
            case 8:
                return "Agosto";
            case 9:
                return "Setembro";
            case 10:
                return "Outubro";
            case 11:
                return "Novembro";
            case 12:
                return "Dezembro";
            default:
                return "--";
        }
    },
    cpf: (value) => {
        if (value.length !== 11) {
            return value;
        }

        let v = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        return `${v[1]}.${v[2]}.${v[3]}-${v[4]}`;
    }
};