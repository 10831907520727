import React from 'react';
import { connect } from "react-redux";

import { attendanceServices } from '../../../_services';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../_components/forms/button';
import Select from '../../../_components/forms/select';
import { mask } from '../../../_components/mask';
import { warningsConstants, alertConstants } from '../../../_constants';
import Alert from '../../../_components/structure/alert';

class Scheduling extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            beneficiary: { code: false },
            beneficiaries: props.beneficiaries, 
            service: props.services,
            location: props.location,
            error: false, 
            submitted: false
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (e) => {
        e.preventDefault();
        let { beneficiary } = this.state;
        beneficiary.code = e.target.value;
        beneficiary.name = e.target.textContent;
        this.setState({beneficiary});
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { beneficiary, beneficiaries, location } = this.state;
        const { authentication } = this.props;
        const { token } = authentication;
        const { code } = beneficiary;
        if (code === "-1" || code === -1 || !code) {
            this.setState({error: warningsConstants.ER_BENEFICIARY_NOT_SELECTED});
            return;
        } 
        let schedule = {...location, beneficiary: null};
        for (var i = 0; i < beneficiaries.length; i++ ) {
            if (beneficiaries[i].value === code) {
                schedule.beneficiary = beneficiaries[i].value;
                break;
            }
        }


        this.setState({ loading: "Solicitando...", error: false });
        //Faz a requisição ao servidor
        attendanceServices.insert(token, schedule).then(res => {
            this.setState({ loading: false });
            //Se houve a remoção do local de atendimento
            if (res.data.code === "OK") {
                if (this.props.onSave) {
                    this.props.onSave(res.data.id);
                }
            } else {
                this.setState({error: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT});
            }
        }).catch( error => {
            console.log(error);
            this.setState({loading: false, error: error.message ? error.message : warningsConstants.ER_DEFAULT});
        });

    }

    render() {
        const { onCancel } = this.props;
        const { beneficiary, location, beneficiaries, error, loading } = this.state;
        return  (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-6 mb-3">
                    <b className="d-block">Local</b>
                    <span className="d-block font-weight-bold text-dark">{ location.name }</span>
                    <span className="text-muted">{ location.address }</span>
                </div>
                <div className="col-6 mb-3">
                    <b className="d-block">Serviço</b>
                    <span className="d-block font-weight-bold text-dark">{ location.prices.name }</span>
                    <span className=" font-weight-bold text-success">Você pagará { mask.money(parseFloat(location.prices.netValue)) }</span>
                </div>
                <div className="col-12 mb-3">
                    <Select required options={beneficiaries}  value={beneficiary.code} name="code" onChange={e => this.onChange(e)} placeholder="Selecione um beneficiário" label="Beneficiário" />
                </div>
                <div className="col-12 mb-3">
                    <div className="mt-4 d-flex justify-content-end">
                        <Button type="submit" icon={faCheck} color="success" text="Confirmar Solicitação"/>
                        <Button evtClick={onCancel} type="button" icon={faTimes} className="ml-3" color="danger" text="Cancelar"/>
                    </div>
                </div>
                { loading && (<div className="col-12 mb-3"><Alert text={loading} type={alertConstants.LOADING}/></div>) }
                { error && (<div className="col-12 mb-3"><Alert text={error} type={alertConstants.WARNING}/></div>) }
            </form>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};
export default connect(mapStateToProps)(Scheduling);