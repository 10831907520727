import axios from 'axios/dist/axios';
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const ticketServices = {
    stop,
    validation
}

let cancel;
function validation(token, code){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}services/update/validTicket.php?code=${code}`, qs.stringify({code}), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    },);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
