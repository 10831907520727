export const util = {
    getUrlParameter (url, parameter) {// eslint-disable-next-line
        parameter = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?|&]' + parameter + '=([^&#]*)');
        let results = regex.exec('?' + url.split('?')[1]);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    encode_utf8 (s) {
        return unescape(encodeURIComponent(s));
    },
    decode_utf8 (s) {
        return decodeURIComponent(escape(s));
    }
};