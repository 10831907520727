import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import { faStethoscope, faExclamationTriangle, faWrench, faKey, faSearch, faCheck, faClock, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions } from '../../_actions';
import Select from '../../_components/forms/select';
import TextField from '../../_components/forms/textField';
import Button from '../../_components/forms/button';
import Card from '../../_components/structure/card';
import Alert from '../../_components/structure/alert';
import ListItems from '../../_components/structure/listItems';
import { mask } from '../../_components/mask';
import { util } from '../../_components/util';
import { attendanceServices, categoriesServices, citiesServices, userServices, specialitiesServices, proceduresServices } from '../../_services';
import { warningsConstants, servicesConstants, alertConstants } from '../../_constants';

import Scheduling from './new/scheduling';

class Attendance extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.state = { 
            economy: "R$ 0,00", attendances: 0, totalBeneficiaries: 0, limitBeneficiaries: 0,
            textSearch: "",
            city: -1, 
            category: -1, 
            speciality: -1,
            procedure: -1,
            cities: [],
            listCategories: [],
            listSpecialities: [],
            listProcedures: [],
            placeholderCategories: "Todas categorias",
            placeholderSpecialities: "Todas especialidades",
            placeholderProcedures: "Todos serviços",
            resultSearch: [],
            beneficiaries: [],
            errorSearch: false,
            loadingSearch: false,
            errorScheduling: false,
            loadingScheduling: false,
        };

        this.openScheduling = this.openScheduling.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onSaveNewScheduling = this.onSaveNewScheduling.bind(this);
        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        
        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));        
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    userIsActive = () => {
        const { currentUser } = this.props.authentication;
        if (currentUser.status === "4" || currentUser.status === "0") {
            return false;
        }
        return true;
    }

    loadData() {
        const { dispatch, authentication } = this.props;
        const { token } = authentication;

        if (!this.userIsActive()) {
            dispatch(alertActions.clear());
            return;
        }
        
        return citiesServices.getAllCities().then(res => {
            const {code, data} = res.data;
            if (code === "OK") {
                let cities = [];
                for(var i = 0;i<data.length;i++){
                    cities.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ cities });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }

            /*return categoriesServices.getCategories();
        }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let allCategories = [];
                for (var i = 0;i<data.length;i++) {
                    allCategories.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ allCategories });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }
            return specialitiesServices.getSpecialities();
        }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let allSpecialities = [];
                for (var i = 0;i<data.length;i++) {
                    allSpecialities.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ allSpecialities });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }
            return proceduresServices.getProcedures();
        }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let allProcedures = [];
                for (var i = 0;i<data.length;i++) {
                    allProcedures.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ allProcedures });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }*/

            return userServices.getBeneficiaries(token, {
                type: 1,
                page: 1,
                status: true
            });
        }).then(res => {
            const {code, data} = res.data;
            if (code === "OK") {
                let beneficiaries = [];
                data.forEach(ben => {
                    beneficiaries.push({
                        text: ben.name,
                        value: ben.id
                    });
                });
                this.setState({ beneficiaries });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response: false};
                throw error;
            }
            dispatch(alertActions.clear());

            let location = localStorage.getItem('generateGuide') ? localStorage.getItem('generateGuide') : util.getUrlParameter(window.location.href, "location");
            if (location !== "") {
                this.openScheduling({infoExtra: JSON.parse(window.atob(location))});
            }
            localStorage.removeItem('generateGuide');
        }).catch( (error) =>{
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    loadCategories = async () => {
        const {city} = this.state;
        const { dispatch } = this.props;
        return categoriesServices.getCategories(city).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listCategories = [];
                for (var i = 0;i<data.length;i++) {
                    listCategories.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ listCategories, placeholderCategories: "Todas categorias" });
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }
        }).catch( (error) =>{
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    loadSpecialities = async () => {
        const { dispatch } = this.props;
        const { category, city } = this.state;
        return specialitiesServices.getSpecialities({category, city}).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listSpecialities = [];
                for (var i = 0;i<data.length;i++) {
                    listSpecialities.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                    this.setState({ listSpecialities,  placeholderSpecialities: "Todas especialidades"});
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }
        }).catch( (error) =>{
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    loadProcedures = async () => {
        const { dispatch } = this.props;
        const { category, city, speciality } = this.state;
        return proceduresServices.getProcedures({ category, city, speciality }).then(res => {
            const {code, data } = res.data;
            if (code === "OK") {
                let listProcedures = [];
                for (var i = 0;i<data.length;i++) {
                    listProcedures.push({
                        text: data[i].name,
                        value: data[i].id
                    });
                }
                this.setState({ listProcedures,  placeholderProcedures: "Todos serviços"});
            } else {
                let error = {message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT, response:  false};
                throw error;
            }
        }).catch( (error) =>{
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    onChangeSearch = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
        
        switch (evt.target.name) {
            case 'city':
                this.setState({placeholderCategories: "Carregando..."});
                this.setState({listSpecialities: [], listProcedures: [], category: -1, speciality: -1, procedure: -1});
                setTimeout(async () => {
                    await this.loadCategories();
                }, 1000);
                break;
            case 'category':
                this.setState({placeholderSpecialities: "Carregando..."});
                this.setState({listProcedures: [], speciality: -1, procedure: -1});
                setTimeout(async () => {
                    await this.loadSpecialities();
                }, 1000);
                break;
            case 'speciality':
                this.setState({placeholderProcedures: "Carregando..."});
                this.setState({procedure: -1});
                setTimeout(async () => {
                    await this.loadProcedures();
                }, 1000);
                break;
            default: 
                break;
        }
    }

    openScheduling = (params) => {
        const { dispatch } = this.props;
        const { beneficiaries } = this.state;

        dispatch(alertActions.dialog({
            iconTitle: faClock,
            title: "Solicitação de atendimento",
            content: (
            <Scheduling 
                onSave={this.onSaveNewScheduling} 
                location={params.infoExtra}
                onCancel={()=>{
                    const { dispatch } = this.props;
                    dispatch(alertActions.clear());
                }} 
                beneficiaries={beneficiaries}
                />
            ),
        }));
    }

    onSaveNewScheduling = (id) => {
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        dispatch(alertActions.success({
            title:"Solicitação de Atendimento", 
            iconTitle: faStethoscope, 
            icon:faCheckCircle, 
            message: (
                <div>
                    <span className="mb-3 d-block">{`${warningsConstants.SC_NEW_SCHEDULING}`}</span>
                    <span className="font-weight-normal d-block mb-3 text-info">Agora é só você ligar para o profissional, agendar o serviço e informar o código do cupom.</span>
                    <span className="font-weight-normal text-muted">O código do cupom é </span>
                    <h3 className="text-dark font-weight-bold">{id}</h3>
                </div>
            )
        }));
        return false;
    }

    onSubmitSearch = (evt) => {
        evt.preventDefault();
        const { token } = this.props.authentication;
        const { procedure, speciality, category, textSearch, city } = this.state;
        const { dispatch } = this.props;

        this.setState({ errorSearch: false, loadingSearch: true });
        attendanceServices.searchAttendances(token, { city, speciality, category, procedure, text: textSearch }).then(res => {

            if (res.data.code === "OK") {
                let resultSearch = [];
                let results = [];

                res.data.data.forEach( (attendance) => {
                    if (results.indexOf(`${attendance.id}-${attendance.prices.idSpeciality}-${attendance.prices.id}`) >= 0) {
                        return;
                    }
                    results.push(`${attendance.id}-${attendance.prices.idSpeciality}-${attendance.prices.id}`);
                    resultSearch.push({
                        key: `${attendance.id}-${attendance.prices.idSpeciality}-${attendance.prices.id}`,
                        infoMain: 0,
                        info:[
                            { 
                                name:"Serviço", 
                                value: (<b className="font-weigh-bold text-info">{attendance.prices.nameSpeciality} - {attendance.prices.name}</b>), 
                                col: 4
                            },
                            { name: `${attendance.name} - ${attendance.nameProfessional}`, value: `${attendance.address} ${attendance.city}`, col: 5},
                            { name:"Desconto", value: mask.money(parseFloat(attendance.prices.discount)) },
                            { name:"Valor", value: mask.money(parseFloat(attendance.prices.netValue)) }
                        ],
                        buttons: [
                            {text:'Gerar Desconto', infoExtra:attendance, icon:faCheck, color:'success', fn:this.openScheduling}
                        ]
                    });
                });
                if (resultSearch.length > 0) {
                    this.setState({ resultSearch, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  errorSearch: false, loadingSearch: false});
                } else {
                    this.setState({ resultSearch, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  errorSearch: {
                        message: warningsConstants.WA_ATTENDANCES_NOT_FOUND
                    }, loadingSearch: false});
                }
            } else {
                this.setState({ errorSearch: res.data, resultSearch: [], currentPage: 1, lastPage: 1, loadingSearch: false });
            }

            dispatch(alertActions.clear());
        }).catch( error => {
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, attendances: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    render() {
        const {
            cities, listCategories,listProcedures,
            listSpecialities, textSearch, city, category, speciality, procedure, errorSearch, loadingSearch,
            resultSearch, redirect, placeholderCategories, placeholderSpecialities, placeholderProcedures
        } = this.state;

        if (redirect) {
            return <Redirect push to={redirect} />;
        }

        if (!this.userIsActive()) {
            return (
                <div>
                    <section className="m-3">
                        <div className="row justify-content-center">
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <Card title="" className="text-center">
                                    <FontAwesomeIcon icon={faExclamationCircle} color="#1c72bc" size="2x" className=""/>
                                    <h5 className="mt-3 text-muted">Seu cadastro está inativo, verifique a seção de mensalidades ou entre em contato conosco.</h5>
                                    <p className="text-info font-weight-bold">(85) 3371-4377 / 98948-6161</p>
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }

        return (
            <div>
                <section className="m-3">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Card title="Gere seu código de desconto" color="#1c72bc">
                                <h5 className="text-muted">Encontre o local do atendimento</h5>
                                <form onSubmit={this.onSubmitSearch} className="row mb-3">
                                    <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3">
                                        <Select onChange={e => this.onChangeSearch(e)} options={cities} value={city} name="city"  placeholder="Todas as cidades"/>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3">
                                        <Select disabled={listCategories.length === 0} onChange={e => this.onChangeSearch(e)} options={listCategories} value={category} name="category"  placeholder={placeholderCategories}/>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3">
                                        <Select disabled={listSpecialities.length === 0} onChange={e => this.onChangeSearch(e)} options={listSpecialities} value={speciality} name="speciality" placeholder={placeholderSpecialities}/>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-3">
                                        <Select disabled={listProcedures.length === 0} onChange={e => this.onChangeSearch(e)} options={listProcedures} value={procedure} name="procedure" placeholder={placeholderProcedures}/>
                                    </div>
                                    <div className="col-9 mb-3">
                                        <TextField onChange={e => this.onChangeSearch(e)} size="lg" name="textSearch" placeholder="O que você precisa?" value={textSearch}/>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <Button icon={faSearch} size="lg" className="w-100" color="info" text="Pesquisar"/>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12">
                                        { loadingSearch && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                                        { (!loadingSearch && errorSearch) && <Alert text={errorSearch.message} type={alertConstants.WARNING}></Alert> }
                                        { (!loadingSearch && !errorSearch) &&  <ListItems items={resultSearch}/> }
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(Attendance);