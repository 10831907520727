import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const categoriesServices = {
    stop,
    getCategories,
    disable,
    insert,
    insertSpeciality,
    enable
}

const CancelToken = axios.CancelToken;
let cancel;

function insertSpeciality(token, speciality, location){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    speciality.location = location;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/speciality.php`, qs.stringify(speciality), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

function enable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}services/update/recycleCategory.php?uid=${uid}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}services/delete/category.php?uid=${uid}`);
}

function insert(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/category.php?uid=${uid}`);
}

function getCategories(city) {
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/categories.php${ city ? `?city=${city}` : '' }`,
        responseType: 'json',
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
