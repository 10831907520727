import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { authConstants } from '../_constants';

//Pages ADMIN
import Dashboard from '../_pages/admin/dashboard';
import Users from '../_pages/admin/users';
import NewUser from '../_pages/admin/new/user';
import EditUser from '../_pages/admin/edit/user';
import HistoryAdmin from '../_pages/admin/history';
import SettingsAdmin from '../_pages/admin/settings';
import Locations from '../_pages/admin/locations';
import NewLocation from '../_pages/admin/new/location';
import EditLocation from '../_pages/admin/edit/location';


//Pages CLIENTE
import DashboardClient from '../_pages/client/dashboard';
import AttendanceClient from '../_pages/client/attendance';
import SettingsClient from '../_pages/client/settings';
import FinancialClient from '../_pages/client/financial';
import HistoryClient from '../_pages/client/history';
import UsersClient from '../_pages/client/users';
import NewUserClient from '../_pages/client/new/user';
import EditUserClient from '../_pages/client/edit/user';


const Routes = ({type: Type}) => {
    switch (Type) {
        case authConstants.ADMIN:
            return (
                <main className="main-admin">
                    <Switch>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route exact path="/locais" component={Locations} />
                        <Route exact path="/locais/novo" component={NewLocation} />
                        <Route exact path="/locais/editar/:uid" component={EditLocation} />
                        <Route exact path="/usuarios" component={Users} />
                        <Route exact path="/usuarios/novo" component={NewUser} />
                        <Route exact path="/usuarios/editar/:uid" component={EditUser} />
                        <Route path="/historico" component={HistoryAdmin} />
                        <Route path="/configuracoes" component={SettingsAdmin} />
                        <Redirect to="/dashboard"/>
                    </Switch>
                </main>
            )
        case authConstants.CLIENT:
            return (
                <main className="main-client">
                    <Switch>
                        <Route path="/attendance" component={AttendanceClient} />
                        <Route path="/dashboard" component={DashboardClient} />
                        <Route path="/mensalidades" component={FinancialClient} />
                        <Route path="/meusdados" component={SettingsClient} />
                        <Route path="/historico" component={HistoryClient} />
                        <Route exact path="/beneficiarios" component={UsersClient} />
                        <Route exact path="/beneficiarios/novo" component={NewUserClient} />
                        <Route exact path="/beneficiarios/editar/:uid" component={EditUserClient} />
                        <Redirect to="/dashboard"/>
                    </Switch>
                </main>
            )
        default:
        break;
    }
};

export default Routes;