import React from 'react';
import { connect } from "react-redux";
import { alertActions } from '../../_actions';
import  { alertConstants }  from '../../_constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSync } from '@fortawesome/free-solid-svg-icons';

class Dialog extends React.Component {
    constructor(props){
        super(props);
        this.closeDialog = this.closeDialog.bind(this);
        this.evtPrimary = this.evtPrimary.bind(this);
        this.evtSecondary = this.evtSecondary.bind(this);
        this.evtKeyboard = this.evtKeyboard.bind(this);
    }

    closeDialog(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        const { eventOnClose } = this.props.alert.params;
        if (typeof eventOnClose === 'function') {
            eventOnClose(e);
        }
    }

    evtPrimary(evt){
        evt.preventDefault();
        const { eventPrimary } = this.props.alert.params;
        if (typeof eventPrimary === 'function') {
            eventPrimary(evt, ()=>{this.closeDialog(evt);});
        } else {
            this.closeDialog(evt);
        }
    }
    
    evtSecondary(evt){
        evt.preventDefault();
        const { eventSecondary } = this.props.alert.params;
        if (typeof eventSecondary === 'function') {
            eventSecondary(evt);
        }
        this.closeDialog(evt);
    }

    evtKeyboard(evt){
        evt.preventDefault();
        
        //ESC
        if (evt.keyCode === 27) {
            this.closeDialog(evt);
        }
        //ENTER
        if (evt.keyCode === 13) {
            this.evtPrimary(evt);
        }
    }

    componentDidMount(){
        const { type } = this.props.alert;
        if (type !== alertConstants.DIALOG) {
            document.addEventListener("keydown", this.evtKeyboard);
        }
    }
    
    componentWillUnmount() {
        const { type } = this.props.alert;
        if (type !== alertConstants.DIALOG) {
            document.removeEventListener("keydown", this.evtKeyboard);
        }
    }

    render() {
        const { allScreen, content, btnSecondaryOutline, btnPrimaryOutline, title, icon, message, iconTitle, textBtnPrimary, colorBtnPrimary, textBtnSecondary, colorBtnSecondary } = this.props.alert.params;
        const { type } = this.props.alert;
        let classText;
        switch(type){
            case alertConstants.SUCCESS:
                //classType = "alert-success";
                classText = "text-center font-weight-bold text-success";
                break;
            case alertConstants.WARNING:
                //classType = "alert-warning";
                classText = "text-center font-weight-bold text-warning";
                break;
            case alertConstants.ERROR:
                //classType = "alert-danger";
                classText = "text-center font-weight-bold text-danger";
                break;
            case alertConstants.INFO:
                //classType = "alert-info";
                classText = "text-center font-weight-bold font-weight-bold text-info";
                break;
            case alertConstants.PRIMARY:
                //classType = "alert-info";
                classText = "text-center font-weight-bold font-weight-bold text-primary";
                break;
            case alertConstants.CONFIRM:
                //classType = "";
                classText = "text-center font-weight-bold text-primary";
                break;
            case alertConstants.LOADING:
                //classType = "";
                classText = "text-center font-weight-bold text-dark";
                break;
            default:
                //classType = "";
                classText = "text-primary";
        }

        let iconObj = (icon && <FontAwesomeIcon size="2x" icon={icon}/>);
        return (
            <div className={"bg-dialog d-flex justify-content-center align-items-center "}>
                <div className={"dialog-body d-flex align-items-center justify-content-center rounded shadow p-3 col-10 col-sm-10 col-md-8 col-lg-7 col-xl-6 "+(allScreen ? " allScreen" : "")}>
                <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <h5 className="m-2 font-weight-bold float-left">{iconTitle && <FontAwesomeIcon icon={iconTitle}/>} {title}</h5>
                            { type !== alertConstants.LOADING &&
                            <button onClick={this.closeDialog} className="btn py-0 px-3 text-muted btn-link"><FontAwesomeIcon size="2x" icon={faTimes}/></button>}
                        </div>
                        <div className={"border-top border-light "+(classText)}>
                            { (type !== alertConstants.LOADING && iconObj) && <div>{iconObj}<br/></div>}
                            { type === alertConstants.LOADING && <div><FontAwesomeIcon size="2x" spin icon={faSync}/><br/></div>}
                            { message && (<div className="text-dialog">{ message }</div>)}
                            { content && <div className="mt-3 mx-3">{content}</div> }
                        </div>
                        { 
                            type !== alertConstants.DIALOG && 
                            <div className="text-center pt-4">
                                { type !== alertConstants.LOADING && 
                                    <button onClick={this.evtPrimary} className={"btn btn-"+(btnPrimaryOutline ? "outline-" : "")+(!colorBtnPrimary ? "primary" : colorBtnPrimary)}>{!textBtnPrimary ? "OK" : textBtnPrimary}</button>}
                                { type === alertConstants.CONFIRM && 
                                    <button onClick={this.evtSecondary} className={"btn ml-3 btn-"+(btnSecondaryOutline ? "outline-" : "")+(!colorBtnSecondary ? "danger" : colorBtnSecondary)}>{!textBtnSecondary ? "Cancelar" : textBtnSecondary}</button> }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
};
export default connect(mapStateToProps)(Dialog);