import axios from 'axios/dist/axios';
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const userServices = {
    get,
    enable,
    disable,
    stop,
    update,
    updateClient,
    insert,
    getAll,
    getBeneficiaries,
    insertBeneficiary
}

const CancelToken = axios.CancelToken;
let cancel;
function get(token, uid) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}services/read/user.php?uid=${uid}`);
}

function enable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}users-recycle/${uid}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}services/delete/user.php?uid=${uid}`);
}

function updateClient(token, user){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    user.cpf = user.cpf.replace(/[^\d]+/g, '');
    user.phone = user.phone.replace(/[^\d]+/g, '');
    user.birthday = user.birthday.split("/").reverse().join("-");
    return axios.put(`${servicesConstants.URL_SERVER}services/update/user.php?uid=${user.id}`, qs.stringify(user), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    },);
}

function update(token, user){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.put(`${servicesConstants.URL_SERVER}services/update/user.php?uid=${user.id}`, qs.stringify(user), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    },);
}

function insert(token, user){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/user.php`, qs.stringify(user), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

function insertBeneficiary(token, user) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    user.cpf = user.cpf.replace(/[^\d]+/g, '');
    user.phone = user.phone.replace(/[^\d]+/g, '');
    user.birthday = user.birthday.split("/").reverse().join("-");
    return axios.post(`${servicesConstants.URL_SERVER}services/create/beneficiary.php`, qs.stringify(user), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

function getAll(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/user.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getBeneficiaries(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/beneficiary.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
