import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../_actions';
import { userServices, authServices } from '../../_services';

import TextField from '../../_components/forms/textField';
import Button from '../../_components/forms/button';
import Alert from '../../_components/structure/alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faExclamationTriangle, faCheckCircle, faTimesCircle, faWrench, faKey} from '@fortawesome/free-solid-svg-icons';
import { warningsConstants, alertConstants } from '../../_constants';

class Settings extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = { 
            user: {id:"", name: "", phone: "", cpf: "", rg: "", birthday:"", email: ""},
            redirect: false,
            states: [],
            cities: []
        };

        this.loadData();

        this.submitUser = this.submitUser.bind(this);
        this.onChange = this.onChange.bind(this);

        const { dispatch } = props;
        dispatch(alertActions.clear());
        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
    }

    loadData() {
        const { token } = this.props.authentication;
        console.log(token);
        const { dispatch } = this.props;
        authServices.getDataUser(token).then(resp => {
            const {code} = resp.data;
            console.log(resp.data);
            if (code === "OK") {
                let user = Object.assign(this.state.user, {   
                    id:resp.data.user.id, 
                    name:resp.data.user.name, 
                    cpf:resp.data.user.cpf, 
                    rg:resp.data.user.rg, 
                    birthday: resp.data.user.birthday.split("-").reverse().join("/"), 
                    phone: resp.data.user.phone ? resp.data.user.phone.replace(/[^\d]+/g, '') : "", 
                    email: resp.data.user.email
                });
                this.setState({user});

                dispatch(alertActions.clear());
            } else {
                dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Editando configurações", message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( (error) =>{
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        }); 
    }

    submitUser(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { user } = this.state;
        dispatch(alertActions.clear());

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        userServices.updateClient(token, user).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Editando configurações", iconTitle: faWrench, icon:faCheckCircle, message: warningsConstants.SC_UPDATE_USER}));
            } else {
                dispatch(alertActions.warning({iconTitle: faWrench, icon:faExclamationTriangle, title:"Editando configurações", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( (error) =>{
            console.log(error);
            const { dispatch } = this.props;
            const { status, data } = error.response;
            if (status === 403) {
                this.setState({redirect: "/logout"});
                return;
            } 
            if (status === 400) {
                dispatch(alertActions.clear());
                dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: data.message ? data.message : warningsConstants.ER_DEFAULT}));
                return;
            }
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Editando usuário", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        });
    }
    
    onChange = evt => {
        let user = this.state.user;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        user[evt.target.name] = evt.target.value;
        this.setState({user});
    }

    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { user, redirect } = this.state;
        if (redirect) {
            return <Redirect push to={redirect} />;
        }
        const { name, email, cpf, phone, rg, birthday } = user;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <section className="col-sm-12 col-md-12 col-lg-9 col-xl-8">
                        <h2 className="title-page"><FontAwesomeIcon icon={faWrench} /> Meus Dados</h2>
                        <hr/>
                        <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                        <div className="card p-3">
                            <div className="card-body">
                                <div className="row">
                                    <Alert sizeIcon="2x" sizeText="md" type={alertConstants.INFO} text="Se necessária a alteração de CPF, Nome ou Data de Nascimento. Entre contato com o nosso suporte."/>
                                </div>
                                <form className="row" onSubmit={this.submitUser}>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField disabled={true} value={name} required={true} name="name" onChange={e => this.onChange(e)} respInvalid="Informe o nome do usuário" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,60}$/} type="text" placeholder="Nome do usuário" label="Nome" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField disabled={true} value={cpf} mask="cpf" required={true} name="cpf" onChange={e => this.onChange(e)} type="text" placeholder="000.000.000-00" label="CPF:" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={email} required={false} name="email" onChange={e => this.onChange(e)} respInvalid="E-mail inválido." pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/} type="email" placeholder="email@exemplo.com" label="E-mail" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={rg} required={true} name="rg" onChange={e => this.onChange(e)} type="text" placeholder="Número do RG" label="RG:" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField value={phone} mask="phone" required={false} name="phone" onChange={e => this.onChange(e)} type="tel" placeholder="(DDD) 00000-0000" label="Telefone" size="lg" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                                        <TextField disabled={true} value={birthday} mask="date"  required={true} name="birthday" onChange={e => this.onChange(e)} type="text" placeholder="DD/MM/AAAA" label="Data de Nascimento" size="lg" />
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <Button type="submit" icon={faSave} text="Salvar" color="success" className="mr-2 "/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(Settings);