import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const attendanceServices = {
    get,
    stop,
    update,
    insert,
    getDashboard,
    searchAttendances,
    getAll
}

const CancelToken = axios.CancelToken;
let cancel;
function get(token, uid) {
   /* axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}users/${uid}`);
*/
    return new Promise( (resolve, reject) => {
        resolve({data:{ code: "OK", user:{
            id: 2,
            name: "Steve Stranger",
            email: "drstranger@marvel.com",
            phone: "889888843247",
            cpf: "",
            addresses: [
                {
                    id: 1, street: "Rua Joaquim Figueiredo", number: "155", district: "Triangulo", 
                    city: {id: 3, name:"Juazeiro do Norte"}, 
                    uf: {id: 3, name:"Ceará"}, 
                    complement: ""
                }
            ]
        }}});
    });
}

function update(token, user){
    /*axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    let formData = new FormData();
    formData.append("photo", user.photo);
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("city", user.city);
    formData.append("uf", user.uf);
    formData.append("password", user.password);
    return axios.put(`${servicesConstants.URL_SERVER}users/${user.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });*/

    return new Promise( (resolve, reject) => {
        resolve({data:{ code: "OK"}});
    });
}

function insert(token, scheduling){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(`${servicesConstants.URL_SERVER}services/create/scheduling.php`, qs.stringify(scheduling), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}


function searchAttendances(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/search-agreed.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getAll(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `services/read/attendance.php`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function getDashboard(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}services/read/dashboard.php`);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
