import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { alertActions } from '../../../_actions';
import { userServices, citiesServices } from '../../../_services';

import Breadcrumb from '../../../_components/structure/breadcrumb';
import TextField from '../../../_components/forms/textField';
import InputFile from '../../../_components/forms/inputFile';
import Button from '../../../_components/forms/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faLock, faUserTie, faExclamationTriangle, faCheckCircle, faTimesCircle, faPencilAlt, faExclamationCircle, faLockOpen, faKey, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { warningsConstants, alertConstants } from '../../../_constants';
import Select from '../../../_components/forms/select';
import Alert from '../../../_components/structure/alert';

class User extends React.Component {

    constructor(props) {
        super(props);

        
        this.state = { 
            user: {id:"", code:"", name:"", uf: "", city: "", password: false, rePassword: false, phone: "", email: "", photo: ""},
            redirect: false,loading: false,
            states: [], cities: [],
            changePassword: false
        };
        this.loadUser();

        this.submitUser = this.submitUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.back = this.back.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    loadUser() {
        const { token } = this.props.authentication;
        const { dispatch } = this.props;
        if (this.props.match.params.uid === "") {
            dispatch(alertActions.clear());
            dispatch(alertActions.error({title:"Editando administrador", iconTitle: faUserTie, icon:faExclamationCircle, message: warningsConstants.ER_PARAMS_URL, eventOnClose: this.back}));
        } else {
            userServices.get(token, this.props.match.params.uid).then(resp => {
                const {code} = resp.data;
                if (code === "OK") {
                    let user = Object.assign(this.state.user, {   
                            id:resp.data.user.id, 
                            name:resp.data.user.name, 
                            phone: resp.data.user.phone ? resp.data.user.phone.substr(3).replace(/[^\d]+/g, '') : "", 
                            email: resp.data.user.email,
                            photo: resp.data.user.photo,
                            city: resp.data.user.address.city ? resp.data.ser.address.city.id : "", 
                            uf: resp.data.user.address.uf ? resp.data.user.address.uf : "", 
                        });
                    this.setState({user, loading: false});
                    if (user.uf !== "" && user.uf) {
                        this.loadCities(user.uf);
                    }
                    dispatch(alertActions.clear());
                } else {
                    dispatch(alertActions.warning({iconTitle: faUserTie, icon:faExclamationTriangle, title:"Editando administrador", eventOnClose: this.back, message: resp.data.message ? resp.data.message : warningsConstants.ER_DEFAULT}));
                }
            }).catch( (error) => {
                const { status } = error.response; 
                if (status === 403) {
                    this.setState({redirect: "/logout"});
                    return;
                }
                dispatch(alertActions.clear());
                dispatch(alertActions.warning({iconTitle: faUserTie, icon:faExclamationTriangle, title:"Carregando Dados", message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
            }); 
        }
    }

    submitUser(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { token } = this.props.authentication;
        const { user, changePassword } = this.state;
        dispatch(alertActions.clear());

        //Senha não repetida
        if (changePassword) {
            if (user.password !== user.rePassword) {
                dispatch(alertActions.warning({iconTitle: faUserTie, icon:faExclamationTriangle, title:"Ops...", message: warningsConstants.ER_PASSWORD_REPEAT}));
                return;
            }
        }

        dispatch(alertActions.loading({message: warningsConstants.LD_DEFAULT}));
        userServices.update(token, user).then(res => {
            if (res.data.code === "OK") {
                dispatch(alertActions.success({title:"Editando administrador", iconTitle: faUserTie, icon:faCheckCircle, message: warningsConstants.SC_UPDATE_ADMIN}));
            } else {
                dispatch(alertActions.warning({iconTitle: faUserTie, icon:faExclamationTriangle, title:"Editando administrador", message: res.data.message ? res.data.message : warningsConstants.ER_DEFAULT}));
            }
        }).catch( error =>{
            const { dispatch } = this.props;
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            dispatch(alertActions.error({title:"Editando administrador", icon:faTimesCircle, iconTitle:faExclamationTriangle, message: error.message ? error.message : warningsConstants.ER_DEFAULT}));
        });
    }

    back(e) {
        this.setState({redirect: '/administradores'});
    }

    changePassword(e){
        this.setState({changePassword: !this.state.changePassword});
    }

    onChange = evt => {
        let user = this.state.user;
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(alertActions.clear());
        }
        user[evt.target.name] = evt.target.value;
        this.setState({user});

        if (evt.target.name === "uf") {
            this.loadCities(evt.target.value);
        }
    }

    onCompleteUpload = (file, preview) => {
        let user = this.state.user;
        user.photo = file;
        this.setState({user});
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadStates();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadStates() {
        this.setState({ loading: true });
        citiesServices.getStates().then(res => {
            const {code } = res.data;
            if (code === "OK") {
                if (res.data.states.length > 0) {
                    let states = [];
                    for(var i = 0;i<res.data.states.length;i++){
                        states.push({
                            text: res.data.states[i].name,
                            value: res.data.states[i].acronym
                        });
                    }
                    if (this._isMounted) {
                        this.setState({ states });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({ notifications: [], error: {
                            message: warningsConstants.WA_STATES_NOT_FOUND
                        }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, notifications: [], currentPage: 1, lastPage: 1, loading: false });
                }
            }
        }).catch( error =>{
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, notifications: [], currentPage: 1, lastPage: 1, loading: false });
            }
        }); 
    }

    loadCities(uf) {
        citiesServices.getCities(uf).then(res => {
            const {code} = res.data;
            if (code === "OK") {
                if (res.data.cities.length > 0) {
                    let cities = [];
                    for(var i = 0;i<res.data.cities.length;i++){
                        cities.push({
                            text: res.data.cities[i].name,
                            value: res.data.cities[i].id
                        });
                    }
                    if (this._isMounted) {
                        this.setState({ cities });
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({ notifications: [], error: {
                            message: warningsConstants.WA_CITIES_NOT_FOUND
                        }, loading: false});
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: res.data, notifications: [], currentPage: 1, lastPage: 1, loading: false });
                }
            }
        }).catch( error =>{
            const { status } = error.response;
            const { dispatch } = this.props;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            if (this._isMounted) {
                this.setState({ error: error, notifications: [], currentPage: 1, lastPage: 1, loading: false });
            }
        }); 
    }

    render() {
        const { currentUser } = this.props.authentication;
        const { redirect, changePassword, cities, states, loading } = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        const { name, email, phone, photo, password, rePassword, city, uf } = this.state.user;
        return (
            <div>
                <section className="m-3">
                    <Breadcrumb items={[
                        {text: currentUser.enterprise.name, icon: faUniversity, link: null},
                        {text: "Administradores", icon: faUserTie, link: '/administradores'},
                        {text: "Editando", icon: faPencilAlt, link: null},
                        {text: name, icon: null, link: null}
                    ]}/>
                    <h2 className="title-page"><FontAwesomeIcon icon={faUserTie} /> Editando Administrador</h2>
                    <hr/>
                    <p className="text-right"><span className="text-danger">*</span> Campos obrigatórios.</p>
                    <div className="card p-3">
                        <div className="card-body">
                            { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                            { !loading &&
                            <form onSubmit={this.submitUser}>
                                <div className="row">
                                    <div className="col-12 d-flex mb-4 justify-content-center">
                                         <InputFile rounded={true} photo={photo} onComplete={file => this.onCompleteUpload(file)} name="photo" typeFile="image" text="Enviar Foto" size="lg" iconButton={faUserTie}/>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <hr/>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <TextField value={name} required={true} name="name" onChange={e => this.onChange(e)} respInvalid="Inform o nome do administrador" pattern={/^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-'\s]{3,60}$/} type="text" size="lg" placeholder="Nome do administrador" label="Nome" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <TextField value={email} required={true} name="email" onChange={e => this.onChange(e)} respInvalid="E-mail inválido." pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/} type="email" size="lg" placeholder="email@exemplo.com" label="E-mail" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <TextField value={phone} mask="phone" required={false} name="phone" onChange={e => this.onChange(e)} type="tel" size="lg" placeholder="(DDD) 00000-0000" label="Telefone" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <Select options={states} value={uf} name="uf" size="lg" onChange={e => this.onChange(e)} placeholder="Selecione o estado" label="Estado" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <Select options={cities} value={city} name="city" size="lg" onChange={e => this.onChange(e)} placeholder="Selecione a cidade" label="Cidade" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <TextField value={password} required={true} disabled={!changePassword} name="password" respInvalid="A senha deve conter no mínimo 4 digitos." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="senha" size="lg" label="Senha" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                        <TextField value={rePassword} required={true} disabled={!changePassword} name="rePassword" respInvalid="Repita a senha." onChange={e => this.onChange(e)} type="password" pattern={/^[a-z0-9A-Z\-'\s]{4,60}$/} placeholder="Repita a senha" size="lg" label="Repita a senha" />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4 mb-4 d-flex align-items-end">
                                        {changePassword ? 
                                            <Button evtClick={this.changePassword} text="Não alterar senha" size="lg"  className="" color="danger" icon={faLockOpen}/> : 
                                            <Button evtClick={this.changePassword} text="Alterar senha" size="lg" className="" icon={faLock}/>
                                        }
                                    </div>
                                </div>
                                <hr/>
                                <div className="row d-flex justify-content-center">
                                    <Button type="submit" color="success" icon={faSave} text="Salvar" size="lg" className="mr-2 "/>
                                    <Button link="/administradores" color="danger" icon={faTimes} text="Cancelar" className="ml-2 " size="lg"/>
                                </div>
                            </form>}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
};

export default connect(mapStateToProps)(User);