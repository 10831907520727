import { authConstants } from '../_constants';

let token = localStorage.getItem('tokenSimplevida') ? { loggedIn: true, token: localStorage.getItem('tokenSimplevida') , currentUser: JSON.parse(localStorage.getItem('userSimplevida')) } : {};
export default function authentication(state = token, action) {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS:
      return { loggedIn: true, token: action.token, currentUser: action.currentUser};
    case authConstants.USER_SUCCESS:
      return { loggedIn: true, token: action.token, currentUser: action.currentUser};
    case authConstants.USER_FAILURE:
      return { loggedIn: false, token: false, currentUser: null };
    case authConstants.LOGIN_FAILURE:
      return { loggedIn: false, token: false, currentUser: null };
    case authConstants.LOGOUT:
      return {};
    default:
      return state
  }
}