import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { faFileMedicalAlt, faSearch, faExclamationTriangle, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions } from '../../_actions';
import Alert from '../../_components/structure/alert';
import { alertConstants, servicesConstants, warningsConstants } from '../../_constants';
import ListItems from '../../_components/structure/listItems';
import Pagination from '../../_components/structure/pagination';
import Button from '../../_components/forms/button';
import { mask } from '../../_components/mask';
import { attendanceServices } from '../../_services';
import TextField from '../../_components/forms/textField';




class History extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: "",
            endDate: "",
            attendances:[], 
            currentPage: 1, lastPage: 1, search: '', error: false, loading: true, status: true, redirect: false};
        setTimeout(() =>{
            this.loadAttendances(1);
        }, 300);

        this.loadAttendances = this.loadAttendances.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
    }

    loadAttendances(page) {
        const { token } = this.props.authentication;
        const { startDate, endDate } = this.state;
        const { dispatch } = this.props;
        this.setState({ loading: true });
        attendanceServices.getAll(token, {
            init: startDate === "" || startDate == null ? "" : mask.date(startDate).split("/").reverse().join("-"),
            end:  endDate === "" || endDate == null ? "" : mask.date(endDate).split("/").reverse().join("-"),
            page,
        }).then(res => {
            if (res.data.code === "OK") {
                let attendances = [];
                res.data.data.forEach(attendance => {
                    attendances.push({
                        key: attendance.id,
                        infoMain: 1,
                        info:[
                            { name:"Código", value: attendance.id},
                            { name:"Cliente", value: attendance.client, col: 5},
                            { name:"Cupom Gerado", value: mask.datehour(attendance.dateGenerate) },
                            { name:"Validação", value: mask.datehour(attendance.dateAttendance) },
                            { name:"Valor B.", value: mask.money(attendance.grossValue) },
                            { name:"Desconto", value: mask.money(attendance.discount) },
                            { name:"Valor Liq.", value: mask.money(attendance.netValue) }
                        ]
                    });
                });
                if (attendances.length > 0) {
                    this.setState({ attendances, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: false, loading: false});
                } else {
                    this.setState({ attendances, currentPage: res.data.pagination.current, lastPage: res.data.pagination.last,  error: {
                        message: warningsConstants.WA_ATTENDANCES_NOT_FOUND
                    }, loading: false});
                }
            } else {
                this.setState({ error: res.data, attendances: [], currentPage: 1, lastPage: 1, loading: false });
            }
            dispatch(alertActions.clear());
        }).catch( error => {
            dispatch(alertActions.clear());
            if (error.message === servicesConstants.CANCEL_REQUEST) {
                return;
            }
            console.log(error);
            const { status } = error.response;
            if (status === 403) {
                dispatch(alertActions.error({eventOnClose: (() => { this.setState({redirect: "/logout"}); }), iconTitle: faKey, icon:faExclamationTriangle, title:"Login expirado", message: warningsConstants.ER_LOGIN_EXPIRED}));
                return;
            }
            this.setState({ error: error, attendances: [], currentPage: 1, lastPage: 1, loading: false });
        });
    }

    componentWillUnmount() {
        attendanceServices.stop(servicesConstants.CANCEL_REQUEST);
    }

    onChangeStartDate(value) {
        this.setState({ startDate: value });
    }

    onChangeEndDate(value) {
        this.setState({ endDate: value });
    }
    
    submitSearch(e) {
        e.preventDefault();
        this.loadAttendances(1);
    }
    render() {
        const { loading, error, attendances, lastPage, currentPage, redirect, startDate, endDate} = this.state;
        if (redirect) {
            return <Redirect push to={this.state.redirect} />;
        }
        
        return (
            <div>
                <section className="m-3">
                    <h2 className="title-page"><FontAwesomeIcon icon={faFileMedicalAlt} /> Histórico</h2>
                    <hr/>
                    <form onSubmit={ this.submitSearch } className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center mr-3">
                            <label className="font-weight-bold form-label mr-2 mb-0">De:</label>
                            <TextField value={startDate} type="datepicker" onChange={e => this.onChangeStartDate(e)} respInvalid="Informe a data inicial" />
                        </div>
                        <div className="d-flex align-items-center mr-3">
                            <label className="font-weight-bold form-label mr-2 mb-0">Até:</label>
                            <TextField value={endDate} type="datepicker" onChange={e => this.onChangeEndDate(e)} respInvalid="Informe a data final" />
                        </div>
                        <Button type="submit" className="btn-fiell" text={"Filtrar"} icon={faSearch} color={"primary"}/>
                    </form>
                    <div className="card">
                        <div className="card-body">
                        { loading && <Alert text="Carregando..." type={alertConstants.LOADING}></Alert> }
                        { (!loading && error) && <Alert text={error.message} type={alertConstants.WARNING}></Alert> }
                        { (!loading && !error) &&  <ListItems items={attendances}/> }
                        </div>
                    </div>
                </section>
                <footer>
                    { (!loading && !error && lastPage > 1) && 
                        <Pagination fnChangePage={this.loadUsers} currentPage={currentPage} lastPage={lastPage}/>
                    }   
                </footer>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(History);