import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.eventClick = this.eventClick.bind(this);
    }

    eventClick(evt) {
        evt.preventDefault();
        if (typeof this.props.evtClick === 'object') {
            return this.props.evtClick.fn(this.props.evtClick.params);
        } else if (typeof this.props.evtClick === 'function') {
            return this.props.evtClick(evt);
        }
    }

    render() {
        let {outline, className, color, evtClick, link, icon, type, size, text, externalLink} = this.props;
        let classBtn = "btn "+(className ? className: '');
        if (size === "lg") {
            classBtn += " btn-lg";
        } else  if (size === "sm") {
            classBtn += " btn-sm";
        }

        if (color === "" || color === undefined) {
            classBtn += (outline ? " btn-outline-primary":" btn-primary");
        } else {
            classBtn += (outline ? " btn-outline-"+color:" btn-"+color);
        }
        if (evtClick) {
            return (<button  onClick={this.eventClick} type={type} className={classBtn} >{ icon &&  <FontAwesomeIcon icon={icon}/>} {text}</button>);
        }
        if (link) {
            return (<Link  to={link} className={classBtn}>{ icon &&  <FontAwesomeIcon icon={icon}/>} {text}</Link>);
        } else if (externalLink) {
            return (<button onClick={() => { window.open(externalLink); }} className={classBtn}>{ icon &&  <FontAwesomeIcon icon={icon}/>} {text}</button>);
        }
        return (<button type={type} className={classBtn} >{ icon &&  <FontAwesomeIcon icon={icon}/>} {text}</button>);
    }
}
