import { combineReducers } from 'redux';
import authentication  from './auth.reducer';
import alert  from './alert.reducer';
import notification  from './notification.reducer';

const rootReducer = combineReducers({
  authentication,
  notification,
  alert
});

export default rootReducer;