import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from '../forms/button';
import '../../_css/components.css';


class ListItems extends React.Component {
    render() {
        const { items } = this.props;
        return (
            <ul className="list-group list-group-flush">
                { items.map(item => (
                     (item.link ?
                        <Link to={item.link} key={item.key} className="list-group-item list-group-item-action">
                            <div className="row justify-content-between">
                                <div className="d-flex col-sm-12 col-md-9 col-lg-9 col-xl-10">
                                    {item.code && 
                                        <div className="d-flex align-items-center justify-content-center border-right pr-3 mr-3">
                                            <h5 className="m-0"><span className="badge badge-primary">#{item.code}</span></h5>
                                        </div>
                                    }
                                    {item.photo && 
                                        <div className="d-flex align-items-center justify-content-center border-right pr-3 mr-3">
                                            <img src={ item.photo } alt="Foto" className="photo-user shadow-sm rounded-circle" />
                                        </div>
                                    }
                                    { 
                                        item.info.map( (info, i) => (
                                            <div key={i} className={`pr-3 mr-3 ${(info.col ? "col-"+info.col : "")} ${(info.align === "right" ? "ml-auto" : "")}` }>
                                                <small className="font-weight-bold text-dark">{info.name}</small><br/>
                                                <span className="text-primary">{info.value}</span>
                                            </div>
                                        )) 
                                    }
                                </div>
                            </div>
                        </Link> :
                        <li key={item.key} className="list-group-item list-group-item-action">
                            <div className="row justify-content-between">
                                <div className={item.buttons ? "d-flex col-sm-12 col-md-9 col-lg-9 col-xl-10" : "d-flex col-12"}>
                                    {item.code && 
                                        <div className="d-flex align-items-center justify-content-center border-right pr-3 mr-3">
                                            <h5 className="m-0"><span className="badge badge-primary">#{item.code}</span></h5>
                                        </div>
                                    }
                                    {item.photo && 
                                        <div className="d-flex align-items-center justify-content-center border-right pr-3 mr-3">
                                            <img src={ item.photo } alt="Foto" className="photo-user shadow-sm rounded-circle" />
                                        </div>
                                    }
                                    { 
                                        item.info.map( (info, i) => (
                                            <div key={i} className={ `d-flex align-items-center ${ (!item.buttons && i === (item.info.length-1))  ? '' : 'pr-3 mr-3'} ${(info.col ? "col-"+info.col : "")} ${(info.align === "right" ? "ml-auto" : "")}` }>
                                                { info.name && <div><small className="font-weight-bold text-dark d-block">{info.name}</small><span className="text-primary">{info.value}</span></div> }
                                                { !info.name && <span className="text-primary">{info.value}</span> }
                                            </div>
                                        )) 
                                    }
                                </div>
                                    {
                                        item.buttons && 
                                        <div className="d-flex align-items-center col-sm-12 col-md-3 col-lg-3 col-xl-2 ">
                                            <div className="d-flex align-items-center justify-content-end w-100 mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0"> 
                                            {   
                                                item.buttons.map( (btn, i) => {
                                                    if (btn.link) {
                                                        return (<Button {...btn} type="button" key={i}  
                                                        className={"mr-1 "+ (item.buttons.length > 1 ? "col-sm-"+(12/item.buttons.length)+" col-md-"+(12/item.buttons.length) : "" )}></Button>);
                                                    }
                                                    if (btn.fn) {
                                                        return (<Button {...btn} type="button" evtClick={{fn: btn.fn, params:{key: item.key, infoMain:item.infoMain, infoExtra: btn.infoExtra,  info:[...item.info]}}} key={i}  
                                                        className={"mr-1 "+ (item.buttons.length > 1 ? "col-sm-"+(12/item.buttons.length)+" col-md-"+(12/item.buttons.length) : "" )} ></Button>);
                                                    }
                                                    if (btn.externalLink) {
                                                        return (<Button  {...btn} type="button" key={i} 
                                                        className={"mr-1 "+ (item.buttons.length > 1 ? "col-sm-"+(12/item.buttons.length)+" col-md-"+(12/item.buttons.length) : "" )}></Button>);
                                                    }
                                                    return false;
                                                })
                                            }
                                            </div>
                                        </div>
                                    }
                            </div>
                        </li>)
                )) }
            </ul>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
};

export default connect(mapStateToProps)(ListItems);