import axios from 'axios/dist/axios'
import qs from 'qs';
import  { servicesConstants }  from '../_constants';

export const clientServices = {
    get,
    disable,
    stop,
    update,
    importFromFidelizi,
    executeImport,
    insert,
    insertByStudent,
    getByCPF,
    getByPhone,
    getAll
}

const CancelToken = axios.CancelToken;
let cancel;
function get(token, uid) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}clients/${uid}`);
}

function getByCPF(token, cpf) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}clients-by-cpf/${cpf}`);
}

function getByPhone(token, phone) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(`${servicesConstants.URL_SERVER}clients-by-phone/${phone}`);
}

function disable(token, uid){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.delete(`${servicesConstants.URL_SERVER}clients/${uid}`);
}

function executeImport(token, clients){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'POST',
        baseURL: servicesConstants.URL_SERVER,
        url: `clients-execute-import`,
        responseType: 'json',
        data: qs.stringify({clients}),
        headers: { 
            'content-type': 'application/x-www-form-urlencoded'
        }
    };
    return axios(options);
}

function importFromFidelizi(token, file){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(`${servicesConstants.URL_SERVER}clients-import-fidelizi`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


function update(token, client){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    let formData = new FormData();
    formData.append("photo", client.photo);
    formData.append("name", client.name);
    formData.append("cpf", client.cpf);
    formData.append("birthday", client.birthday);
    formData.append("phone", client.phone);
    formData.append("email", client.email);
    formData.append("gender", client.gender);
    formData.append("street", client.street);
    formData.append("number", client.number);
    formData.append("district", client.district);
    formData.append("city", client.city);
    formData.append("uf", client.uf);
    formData.append("enterprise", client.enterprise);
    formData.append("user", client.user);
    return axios.put(`${servicesConstants.URL_SERVER}clients/${client.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


function insert(token, client){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    let formData = new FormData();
    formData.append("photo", client.photo);
    formData.append("name", client.name);
    formData.append("cpf", client.cpf);
    formData.append("birthday", client.birthday);
    formData.append("phone", client.phone);
    formData.append("email", client.email);
    formData.append("gender", client.gender);
    formData.append("street", client.street);
    formData.append("number", client.number);
    formData.append("district", client.district);
    formData.append("city", client.city);
    formData.append("uf", client.uf);
    formData.append("enterprise", client.enterprise);
    formData.append("user", client.user);
    return axios.post(`${servicesConstants.URL_SERVER}clients`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function insertByStudent(token, user){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'POST',
        baseURL: servicesConstants.URL_SERVER,
        url: `clients`,
        responseType: 'json',
        data: qs.stringify(user),
        headers: { 
            'content-type': 'application/x-www-form-urlencoded'
        }
    };
    return axios(options);
}

function getAll(token, filter) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const options = {
        method: 'GET',
        baseURL: servicesConstants.URL_SERVER,
        url: `clients/`,
        responseType: 'json',
        params: filter,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
    return axios(options);
}

function stop(message){
    if (cancel) {
        cancel(message);
    }
}
