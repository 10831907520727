
import React from 'react';

export default class Pagination extends React.Component {

    render() {
        const { currentPage, lastPage } = this.props;
        

        let links = Math.ceil(currentPage / 10);
        let limite2 = links * 10;
        let limite1 = limite2 - 10;
        var i = 0;
        if (limite2 > lastPage) {
            limite2 = lastPage;
        }
        
        let pages = [];
        if (lastPage <= 10) {
            for (i = limite1 + 1; i <= lastPage; i++) {
                pages.push(i);
            }
        } else {
            if (limite2 <= 10) {
                limite1 += 1;
            }
            for (i = limite1; i < lastPage; i++) {
                pages.push(i);
            }
        }
        return (
            <ul className="pagination justify-content-center ">
                { 
                    pages.map(page => (
                        currentPage === page ? <li key={page} className="page-item active"><span className="page-link">{page}</span></li>
                        : <li key={page} className="page-item"><button onClick={() => this.props.fnChangePage(page)} className="page-link">{page}</button></li>
                    ))
                }
            </ul>
        );
    }
}
