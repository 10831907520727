import React from 'react';

import '../../_css/components.css';


export default class MenuSide extends React.Component {
    
    render() {
        return (
        <header className="header-bar shadow-sm d-flex align-items-center justify-content-between position-fixed">
            {this.props.children}
        </header>
        );
    }
}
