import { notificationConstants } from '../_constants';
import izitoast from 'izitoast';

export const notificationActions = {
    success,
    info,
    warning,
    danger,
    clear
};

izitoast.settings({
    timeout: 10000,
    resetOnHover: true,
    maxWidth: 450,
    layout: 2,
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX'
});

function info(icon, title, message, event) {
    let btns = [];
    if (event) {
        btns = [['<button>Abrir</button>', function (instance, toast) {
                event();
            }, true]]
    }
    izitoast.show({
        title,
        icon: (icon && icon.prefix+" icon-notify fa-"+icon.iconName),
        message,
        timeout: 70000,
        theme: 'dark',
        position: 'topRight',
        buttons: btns
    });
    return { type: notificationConstants.INFO, icon, title, message };
}

function success(icon, title, message, event) {
    let btns = [];
    if (event) {
        btns = [['<button>Abrir</button>', function (instance, toast) {
                event();
            }, true]]
    }
    izitoast.show({
        title,
        icon: (icon && icon.prefix+" icon-notify fa-"+icon.iconName),
        message,
        color: "green",
        timeout: 7000,
        theme: 'light',
        position: 'topRight',
        buttons: btns
    });
    return { type: notificationConstants.SUCCESS, icon, title, message };
}

function warning(icon, title, message, event) {
    let btns = [];
    if (event) {
        btns = [['<button>Abrir</button>', function (instance, toast) {
                event();
            }, true]]
    }
    izitoast.show({
        title,
        icon: (icon && icon.prefix+" icon-notify fa-"+icon.iconName),
        message,
        timeout: 7000,
        color: "yellow",
        theme: 'light',
        position: 'topRight',
        buttons: btns
    });
    return { type: notificationConstants.WARNING, icon, title, message };
}

function danger(icon, title, message, event) {
    let btns = [];
    if (event) {
        btns = [['<button>Abrir</button>', function (instance, toast) {
                event();
            }, true]]
    }
    izitoast.show({
        title,
        icon: (icon && icon.prefix+" icon-notify fa-"+icon.iconName),
        message,
        timeout: 7000,
        color: "red",
        theme: 'light',
        position: 'topRight',
        buttons: btns
    });
    return { type: notificationConstants.DANGER, icon, title, message };
}

function clear() {
    izitoast.destroy();
    return { type: notificationConstants.CLEAR };
}