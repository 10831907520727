export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    CONFIRM: 'ALERT_CONFIRM',
    WARNING: 'ALERT_WARNING',
    LOADING: 'ALERT_LOADING',
    PRIMARY: 'ALERT_PRIMARY',
    SECONDARY: 'ALERT_SECONDARY',
    INFO: 'ALERT_INFO',
    ERROR: 'ALERT_ERROR',
    DIALOG: 'ALERT_DIALOG',
    LIGHT: "ALERT_LIGHT",
    CLEAR: 'ALERT_CLEAR'
};
