export const warningsConstants = {
    //LOADING
    LD_DEFAULT: "Carregando...",
    LD_DATA: "Carregando dados...",
    LD_SENDING: "Enviando...",
    LD_SEARCHING_CEP: "Buscando CEP...",

    //ERROR
    ER_DEFAULT: "Houve um erro inesperado, tente novamente.",
    ER_PASSWORD_REPEAT: "Informe novamente a senha.",
    ER_PARAMS_URL: "URL com parâmetros incorretos.",
    ER_LOGIN_EXPIRED: "Faça o login novamente para continuar a utilizar o sistema",
    ER_DATE_INVALID: "Data inválida.",
    ER_SPECIALITY_NOT_SELECTED: "Selecione o tipo de especialidade",
    ER_PROCEDURE_NOT_SELECTED: "Selecione o tipo de procedimento",
    ER_SPECIALITY_ALREADY_SELECTED: "Especialidade já adicionada neste local de atemdimento.",
    ER_CATEGORY_NOT_SELECTED: "Selecione uma catgoria",
    ER_CATEGORY_ALREADY_SELECTED: "Categoria já adicionada.",
    ER_BENEFICIARY_NOT_SELECTED: "Selecione um beneficiário.",

    

    //WARNING
    WA_STATES_NOT_FOUND: "Nenhum estado encontrado.",
    WA_CITIES_NOT_FOUND: "Nenhuma cidade encontrada.",
    WA_FINANCIAL_NOT_FOUND: "Nenhuma movimentação encontrada.",
    WA_ADMIN_NOT_FOUND: "Nenhum usuário administrador encontrado.",
    WA_MONTHLY_NOT_FOUND: "Nenhuma mensalidade encontrada.",
    WA_USERS_NOT_FOUND: "Nenhum usuário encontrado.",
    WA_ADDRESS_NOT_FOUND: "Nenhum endereço encontrado.",
    WA_ATTENDANCES_NOT_FOUND: "Nenhum atendimento encontrado.",
    WA_SERVICE_LOCATION_NOT_FOUND: "Nenhum local de atendimento encontrado.",
    WA_SPECIALITY_NOT_FOUND: "Nenhuma especialidade encontrada.",
    WA_CATEGORY_NOT_FOUND: "Nenhuma categoria encontrada.",


    //SUCCESS
    SC_UPDATE_SETTINGS: "Configurações atualizadas com sucesso.",
    SC_UPDATE_MYDATA: "Seus dados foram atualizados com sucesso.",
    SC_VALIDATION_TICKET: "Cupom validado com sucesso.",
    SC_NEW_BENEFICIARY: "Novo beneficiário adicionado com sucesso.",

    SC_UPDATE_ADMIN: "Usuário atualizado com sucesso.",
    SC_NEW_ADMIN: "Usuário cadastrado com sucesso.",
    SC_DISABLE_ADMIN: "Usuário desabilitado com sucesso.",


    SC_UPDATE_USER: "Usuário atualizado com sucesso.",
    SC_NEW_USER: "Usuário cadastrado com sucesso.",
    SC_DISABLE_USER: "Usuário desabilitado com sucesso.",


    SC_NEW_SERVICE: "Serviço cadastrado com sucesso.",

    SC_UPDATE_SERVICE_LOCATION: "Local de atendimento atualizado com sucesso.",
    SC_NEW_SERVICE_LOCATION: "Local de atendimento cadastrado com sucesso.",
    SC_DISABLE_SERVICE_LOCATION: "Local de atendimento desabilitado com sucesso.",


    SC_NEW_SCHEDULING: "Cupom de desconto gerado com sucesso!",
    
};